import React, { useEffect } from 'react';
import { View, TouchableOpacity, Text, Animated } from 'react-native';
import LoadingIcon from '@images/icons/loading.png';
import { styles } from './styles';

export const Button = ({
  customStyles = {},
  title,
  titleStyle = null,
  iconContainerStyle = null,
  children,
  onPress = () => {},
  disabled = false,
  isFetching = false,
  light = false,
  icon = null,
}) => {
  
  const rotateAnim = new Animated.Value(0);
  const Icon = icon;

  Animated.loop(
    Animated.timing(rotateAnim, {
      toValue: 1,
      duration: 3000,
      useNativeDriver: true,
    }),
    {},
  ).start();

  useEffect(() => {
    if (isFetching) {
      Animated.loop(
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 3000,
          useNativeDriver: true,
        }),
        {},
      ).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const rotateDeg = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.button,
        light ? styles.buttonLight : {},
        !light && (disabled || isFetching) ? styles.disabled : {},
        light && (disabled || isFetching) ? styles.disabledLight : {},
        customStyles,
      ]}
      activeOpacity={0.8}
      disabled={disabled || isFetching}
    >
      {isFetching ? (
        <Animated.Image
          style={[
            styles.loadingIcon,
            {
              transform: [{ rotate: rotateDeg }],
            },
          ]}
          source={LoadingIcon}
        />
      ) : (
        <View style={{ flexDirection: 'row', alignItems: 'center'}}>
          {icon && <View style={iconContainerStyle}><Icon /></View>}
          {title ? (
            <Text
              style={[
                styles.text,
                light ? styles.textLight : {},
                !light && disabled ? styles.textDisabled : {},
                light && disabled ? styles.textDisabledLight : {},
                titleStyle,
              ]}
            >
              {title}
            </Text>
          ) : (
            children
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};
