import React from 'react';
import { SvgXml } from 'react-native-svg';

export default function Favourites({ color = '#BDBDBD', fill = 'none' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1624 1L14.2524 7.26L21.1624 8.27L16.1624 13.14L17.3424 20.02L11.1624 16.77L4.98235 20.02L6.16235 13.14L1.16235 8.27L8.07235 7.26L11.1624 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
 
  // return <SvgXml xml={xml} />;
}
