import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    height: 65,
  },
  inputWrapper: {
    position: 'relative',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.25,
  },
  input: {
    backgroundColor: '#F6F6F6',
    borderColor: '#E8E8E8',
    borderWidth: 1.5,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 18,
    paddingLeft: 20,
    height: 50,
    color: '#000000',
  },
  error: {
    marginTop: 5,
    color: '#D52B1E',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 15,
  },
  icon: {
    padding: 6,
    resizeMode: 'contain',
    position: 'absolute',
    right: 20,
  },
  image: {
    width: 28,
    height: 28,
  },
});
