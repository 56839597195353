import { StyleSheet } from 'react-native';
import Theme from '../../../const/theme';

export const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFFFFF',
    //flex: 1,
    paddingHorizontal: 15,
  },
  body: {
    //flex: 1,
    maxWidth: Theme.widthCountList,
    marginTop: 0,
    paddingBottom: 5,
    alignSelf: 'center',
    backgroundColor: '#FFFFFF',
  },
  contentContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  headerRightContainer: {
    padding: 4,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: '#29D890',
  },
  headerResetContainer: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleReset: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4FAA9F',
    marginRight: 5,
  },
  listContainer: {
    //flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 10,
    backgroundColor: '#FFFFFF',
  },
  countContainer: {
    marginTop: 5,
    marginLeft: 5,
    // paddingTop: 5,
    // paddingBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    backgroundColor: '#F6F6F6',
    // borderColor: 'black',
    // borderWidth: 1,
    width: 32,
    height: 31,
  },
  countActive: {
    backgroundColor: '#A5D9C4',
  },
  successCount: {
    borderColor: '#4FAA9F',
    borderWidth: 2,
  },
  errorCount: {
    borderColor: '#B23452',
    borderWidth: 2,
  },
  testContainer: {
    backgroundColor: '#F6F6F6',
    marginTop: 15,
    borderRadius: 8,
    padding: 30,
  },
  titleCount: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  titleTest: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#000000',
  },
  questionsContainer: {
    maxWidth: 700,
    alignSelf: 'center',
    //alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    //marginHorizontal: 30,
    flexDirection: 'row',
  },
  titleQuestion: {
    flex: 5,
    fontSize: 14,
    fontWeight: 'normal',
  },
  answersContainer: {
    //flex: 1,
    alignSelf: 'center',
    //width: 600,
  },
  answer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: 300,
    maxWidth: 600,
    marginTop: 10,
    paddingTop: 8,
    padding: 10,
  },
  btn: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    marginTop: 10,
    width: '90%',
    maxWidth: 400,
  },
  arrows: {
    paddingHorizontal: 4,
  },
  deprecatedWarning: {
    marginTop: 15,
  },
});
