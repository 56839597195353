import { handleActions } from 'redux-actions';
import { actionsCreatePayment } from '@actions';

export const fetchingCreatePayment = handleActions(
  {
    [actionsCreatePayment.request]() {
      return 'request';
    },
    [actionsCreatePayment.success]() {
      return 'success';
    },
    [actionsCreatePayment.failure]() {
      return 'failure';
    },
  },
  'none',
);
