import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  progressBarContainer: {
    marginTop: 15,
  },
  progressCard: {
    minHeight: 80,
    backgroundColor: '#4FAA9F',
    marginHorizontal: 15,
    marginTop: 30,
  },
  progressRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  progressTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },

  bodyContainer: {
    marginTop: 50,
  },
  cardContainer: {
    marginTop: 15,
  },
  rowName: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowEmail: {
    marginTop: 15,
  },
  personalDataTitle: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  dataTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  logout: {
    width: '100%',
    marginBottom: 20,
  },
  btn: {
    width: '90%',
    maxWidth: 400,
    marginTop: 40,
    marginBottom: 40,
    alignSelf: 'center',
  },
  btnReset: {
    marginBottom: 0,
    marginTop: 30,
    alignSelf: 'center',
    backgroundColor: '#fff',
  },
});
