import React from 'react';
export default function Profile({ color = '#BDBDBD' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8749 4.675C13.8749 6.7 12.2249 8.35 10.1624 8.35C8.09989 8.35 6.44989 6.7 6.44989 4.675C6.44989 2.65 8.09989 1 10.1624 1C12.2249 1 13.8749 2.65 13.8749 4.675Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1624 10.1125C5.17485 10.1125 1.16235 14.0875 1.16235 19H19.1624C19.1624 14.0875 15.1499 10.1125 10.1624 10.1125Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
