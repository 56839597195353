import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  closeIcon: {
    position: 'absolute',
    zIndex: 1000,
    top: 15,
    right: 15,
  },
});
