import { StyleSheet, Platform } from 'react-native';

const styleBody = Platform.select({
  ios: { flex: 1 },
  android: { minHeight: '100%' },
});

export const styles = StyleSheet.create({
  rootTransparent: {
    // flex: 1,
    backgroundColor: 'transparent',
    // backgroundColor: 'green',
    height: '100%',
  },
  root: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  body: {
    ...styleBody,
  },
  main: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  background: {
    flex: 1,
    resizeMode: 'contain',
    height: '100%',
  },
  top: {
    minHeight: 20,
  },
  children: {
    paddingBottom: 0,
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
});
