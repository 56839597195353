import axios from 'axios';
import { createAction } from 'redux-actions';

import { shopId } from '../screens/main/donate/usePayment';

export const instance = axios.create({
  baseURL: 'https://psstver.ru',
  // withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
});

export const instancePayment = axios.create({
  baseURL: 'https://pss-yookassa.dsml.ru/api/v1',
  headers: { 'Content-Type': 'application/json', 'x-shop-id': shopId },
});

export const instancePaymentWidget = axios.create({
  baseURL: 'https://pss-yookassa.dsml.ru/api/v1',
  headers: { 'Content-Type': 'application/json', 'x-shop-id': shopId },
});

// NOTE: ONLY GET
export const instanceMock = axios.create({
  baseURL: 'localhost',
  crossDomain: true,
});

export const createApiActions = (name) => ({
  request: createAction(`REQUEST_${name}`),
  success: createAction(`SUCCESS_${name}`),
  failure: createAction(`FAILURE_${name}`),
});
