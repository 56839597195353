import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: '#D2D2D2',
    borderRadius: 20,
    height: 45,
    paddingLeft: 15,
    paddingRight: 10,
    marginHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    // backgroundColor: 'pink',
    // jstifyContent: 'space-between',
  },
  input: {
    fontSize: 18,
    marginLeft: 10,
    flex: 1,
    //borderRadius: 20,
    height: 45,
    paddingLeft: 15,
    paddingRight: 10,
    alignItems: 'center',    
  },
  cancel: {
    // flex: 0.1,
    alignSelf: 'center',
  },
});
