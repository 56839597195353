import React, { useState, useEffect } from 'react';
import {
  View,
  Image,
  Text,
  Modal,
  TouchableOpacity,
  Alert,
} from 'react-native';
import { ModalTemplate } from '@components/templates';
import { Button } from '@components/atoms';
import { s } from './styles';
import Close from '@images/icons/close-gray.svg';

export const ModeFlow = ({
  visible,
  onToggle,
  onPressMode,
  testResults,
}) => {
  const [visibleReset, setVisibleReset] = useState(false);
  const [resultHasError, setResultHasError] = useState(false);

  const setVisibleResetOff = () => {
    setTimeout(() => setVisibleReset(false), 150);
  };

  useEffect(() => {
    const hasError = !!testResults?.find((r, i) => !r.isUserCorrectAnswer);
    setResultHasError(hasError);
  }, [testResults]);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent
    >
      <ModalTemplate>
        <View style={s.modalContainer}>
          <TouchableOpacity
            onPress={() => {
              onToggle();
              setVisibleResetOff();
            }}
            style={s.icon}
          >
            <Close />
          </TouchableOpacity>

          <Text style={s.title}>Выбор режима</Text>
          <View style={s.btns}>
            <Button
              onPress={() => {
                // onPressMode('test');
                if (!testResults?.length) {
                  onPressMode('test');
                } else {
                  setVisibleReset(true);
                }
              }}
              title="Режим теста"
              customStyles={s.btn}
              disabled={visibleReset}
            />
            {visibleReset && (
              <View style={s.resetContainer}>
                <Button
                  onPress={() => {
                    const result = confirm(
                      'Вы действительно хотите начать заново?',
                    );
                    if (result) {
                      onPressMode('test', true);
                      setVisibleResetOff();
                    }
                  }}
                  title="Начать тест заново"
                  customStyles={s.btnReset}
                />
                <Button
                  onPress={() => {
                    onPressMode('test');
                    setVisibleResetOff();
                  }}
                  title="Продолжить тест"
                  customStyles={s.btnReset}
                />
                {resultHasError && (
                  <Button
                    onPress={() => {
                      onPressMode('fixErrors');
                      setVisibleResetOff();
                    }}
                    title="Работа над ошибками"
                    customStyles={s.btnReset}
                  />
                )}
              </View>
            )}
            <Button
              onPress={() => {
                onPressMode('exam');
                setVisibleResetOff();
              }}
              title="Пройти экзамен *"
              customStyles={s.btn}
            />
            <Text style={s.context}>
              *20 случайных вопросов из блока.{'\n'}Не больше 2х ошибок
            </Text>
          </View>
        </View>
      </ModalTemplate>
    </Modal>
  );
};
