import { StyleSheet } from 'react-native';

export const s = StyleSheet.create({
  root: {
    alignContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    marginTop: 30,
    padding: 15,
    paddingTop: 30,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 400,
  },
  icon: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 20,
    fontSize: 26,
    fontWeight: 'bold',
  },
  detail: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 15,
    fontSize: 16,
    fontWeight: 'normal',
  },
  btns: {
    marginTop: 35,
  },
  btn: {
    marginTop: 0,
    marginBottom: 15,
    backgroundColor: '#4FAA9F',
  },
});
