import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  header: Platform.select({
    ios: {
      paddingLeft: 20,
      backgroundColor: '#448AFF',
      // height: 50,
      // paddingTop: 0,
      borderBottomWidth: 0,
    },
    android: {
      paddingLeft: 20,
      backgroundColor: '#448AFF',
      // height: 80,
      borderBottomWidth: 0,
    },
  }),
  image: {
    // flex: 1,
    paddingTop: 15,
    paddingHorizontal: 30,
    paddingBottom: 20,
    minHeight: 110,
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 22,
    color: 'white',
    fontWeight: 'bold',
  },
  logo: {
    width: 33,
  },
  title: {
    paddingTop: 10,
    fontSize: 14,
    fontWeight: 'normal',
    // textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  details: {
    paddingTop: 20,    
  },
  right: {
    width: 0,
    maxWidth: 0,
  },
  close: {
    width: 40,
    height: 40,
    borderRadius: 6,
    backgroundColor: '#4FAA9F',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    marginTop: 3,
  },
  closeIcon: {
    width: 20,
    height: 20,
  },

  back: {
    width: 40,
    height: 40,
    borderRadius: 6,
    backgroundColor: '#448AFF',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 0,
    marginTop: 0,
  },
  backWrapper: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backIcon: {
    height: 15,
    width: 30,
    resizeMode: 'contain',
  },
});
