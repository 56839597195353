import React from 'react';
// import { SvgXml } from 'react-native-svg';

export default function Logo({ width = 130, height = 130 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 189 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="94.5" cy="94.5" r="94.5" fill="#DFEFEF" />
      <g clip-path="url(#clip0_697_7257)">
        <path
          d="M94.2009 31V52.3375L134.801 77.6225V56.285L94.2009 31Z"
          fill="#FF7900"
        />
        <path
          d="M53.6003 128.715V150.202L94.2008 124.736V103.249L53.6003 128.715Z"
          fill="#00B0AE"
        />
        <path
          d="M134.801 128.545V150.031L175.402 124.565V103.078L134.801 128.545Z"
          fill="#00B0AE"
        />
        <path
          d="M13 103.409V124.896L53.6004 99.4295V77.9426L13 103.409Z"
          fill="#003E87"
        />
        <path
          d="M94.2009 81.7513V103.238L134.801 77.7719V56.285L94.2009 81.7513Z"
          fill="#00B0AE"
        />
        <path
          d="M94.2009 103.249V124.736L134.801 99.2694V77.7719L94.2009 103.249Z"
          fill="#003E87"
        />
        <path
          d="M53.6003 56.4663V77.9532L94.2008 52.4869V31L53.6003 56.4663Z"
          fill="#003E87"
        />
        <path
          d="M94.2009 103.249V124.586L134.801 149.871V128.534L94.2009 103.249Z"
          fill="#FF7900"
        />
        <path
          d="M13 103.409V124.746L53.6004 150.031V128.694L13 103.409Z"
          fill="#FF7900"
        />
      </g>
      <defs>
        <clipPath id="clip0_697_7257">
          <rect
            width="163"
            height="149"
            fill="white"
            transform="translate(13 25)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  // return <SvgXml xml={xml} />;
}
