import React from 'react';
import { SafeAreaView, ImageBackground, View, StatusBar } from 'react-native';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import { Politics } from '@components/organisms';
import BackgroundImage from '@images/auth/backgroundWeb@2x.png';
// import theme from '@const/theme';
import { styles } from './styles';

export const AuthTemplate = ({
  children,
  imageBackground = null,
  height,
  width,
}) => (
  <ImageBackground
    source={BackgroundImage}
    style={[styles.background, { height, width }]}
  >
    <SafeAreaView
      style={[imageBackground ? styles.rootTransparent : styles.root, { height }]}
    >
      <View style={styles.main}>
        <View style={styles.top} />
        <View style={styles.children}>{children}</View>
        
      </View>
    </SafeAreaView>
  </ImageBackground>
);
