import { useState, useEffect } from 'react';
import _ from 'lodash';

import data from '@data/questions';
import tests from '@data/tests';

export const useFilter = () => {
  const [results, setResults] = useState([]);
  const [findQuestion, setFindQuestion] = useState('');
  const [questions, setQuestions] = useState([]);

  const [filterBlock, setFilterBlock] = useState(null);
  const [isOpenedFilterBlockList, toggleFilterBlockList] = useState(false);
  const getBlockValue = (item) => item?.id;
  const getBlockTitle = (item) => item?.title || 'title';

  const [filterTest, setFilterTest] = useState(null);
  const resetFilterTest = () => setFilterTest(null);
  const [isOpenedFilterTestList, toggleFilterTestList] = useState(false);
  const getTestValue = (item) => item?.code || 'code';
  const getTestTitle = (item) => item?.title || 'title';

  const setFilterBlockFn = (id) => {
    toggleFilterBlockList(false);
    resetFilterTest();
    setFilterBlock(id);
  };
  const resetFilterBlock = () => {
    setFilterBlock(null);
    resetFilterTest();
    toggleFilterBlockList(false);
  };

  useEffect(() => {
    let newQuestions = [];
    //-- создание списка вопросов с фильтрацией по блокам
    _.forEach(data, (blockData, blockKey) => {
      if (filterBlock === null || String(filterBlock) === blockKey) {
        _.forEach(blockData, (testData, testKey) => {
          if (filterTest === null || String(filterTest) === testKey) {
            const test = tests?.[blockKey].find(
              (testItem) => String(testItem.code) === testKey,
            );

            const testDataWithTitles = testData.map((item, questionIndex) => {
              return {
                testTitle: test?.title || '',
                questionNumber: questionIndex + 1,
                text: item.text,
                correctAnswers: item.correctAnswers,
                key: blockKey + testKey + item.code,
              };
            });

            newQuestions = [...newQuestions, ...testDataWithTitles];
          }
        });
      }
    });

    setQuestions(newQuestions);
  }, [filterBlock, filterTest]);

  //-- поиск текста в вопросах если более 2-х символов
  useEffect(() => {
    if (findQuestion.length > 2) {
      const newResults = questions.filter((item) =>
        item.text.toLowerCase().includes(findQuestion.toLowerCase()),
      );
      setResults(newResults);
    } else {
      results.length && setResults([]);
    }
  }, [findQuestion, questions, results.length]);

  return {
    results,
    findQuestion,
    setFindQuestion,
    filterBlock,
    isOpenedFilterBlockList,
    toggleFilterBlockList,
    getBlockValue,
    getBlockTitle,
    filterTest,
    setFilterTest,
    resetFilterTest,
    isOpenedFilterTestList,
    toggleFilterTestList,
    getTestValue,
    getTestTitle,
    setFilterBlockFn,
    resetFilterBlock,
  };
};
