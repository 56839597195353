import React from 'react';

import { StyleSheet, View, Text, Alert, Linking } from 'react-native';
// import { Button } from 'react-native-elements';
import { Button } from '@components/atoms';
// import { isIOS, majorVersionIOS } from '@lib/deviceInfo';

// import auth from '@react-native-firebase/auth';
// import { GoogleSignin } from '@react-native-google-signin/google-signin';
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
// import { appleAuth } from '@invertase/react-native-apple-authentication';

/*import {
  LoginManager,
  AccessToken,
  Settings,
  Profile,
} from 'react-native-fbsdk-next'; */
//import VKLogin from 'react-native-vkontakte-login';
// import Odnoklassniki, { Scopes } from 'react-native-odnoklassniki-login';

import AppleSvg from '@images/icons/apple.svg';
import FacebookSvg from '@images/icons/facebook.svg';
import GoogleSvg from '@images/icons/google.svg';
import VkSvg from '@images/icons/vk.svg';
import OkSvg from '@images/icons/ok.svg';

/* eslint no-use-before-define: ["off"] */

const DEFAULT_COLOR = '#111010';
const VK_COLOR = '#2787F5';
const GOOGLE_COLOR = '#111010';
const FACEBOOK_COLOR = '#3B5998';
const OK_COLOR = '#F68634';
const SPINNER_BACKGROUND_COLOR = 'gray';
const TITLE_COLOR_BLACK = '#404040';

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: 'stretch',
  },
  button: {
    justifyContent: 'center',
    width: 400,
    backgroundColor: DEFAULT_COLOR,
    marginBottom: 10,
  },
  buttonVK: {
    backgroundColor: VK_COLOR,
  },
  buttonFacebook: {
    backgroundColor: FACEBOOK_COLOR,
  },
  buttonGoogle: {
    backgroundColor: GOOGLE_COLOR,
  },
  buttonOk: {
    backgroundColor: OK_COLOR,
  },
  buttonTitle: {
    flex: 1.5,
    fontSize: 14,
    lineHeight: 21,
    marginLeft: 15,
    textAlign: 'left',
  },
  buttonTitleOk: {
    flex: 0.7,
    marginLeft: 5,
  },
  iconContainer: {
    alignItems: 'flex-end',
    flex: 0.2,
  },
  buttonTitleBlack: {
    color: TITLE_COLOR_BLACK,
  },
  spinnerBackground: {
    backgroundColor: SPINNER_BACKGROUND_COLOR,
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    opacity: 0.3,
  },
});

const errors = {
  'auth/email-already-in-use': 'email уже используется',
  'auth/account-exists-with-different-credential':
    'Аккаунт уже существует с другими учётными данными',
  'auth/wrong-password': 'Аккаунт уже существует с другими учётными данными',
  'auth/too-many-requests': 'слишком много запросов. Попробуйте позже!',
  'auth/network-request-failed': 'технические проблемы, попробуйте позже',
  'auth/user-not-found': 'аккаунт не найден, попробуйте зарегистрироваться',
};

const err = 'Ошибка!';

/*const createUser = async (email, password, displayName) => {
  await auth().createUserWithEmailAndPassword(email, password);
  if (displayName) {
    await auth().currentUser.updateProfile({ displayName });
  }
};

const createOrSignIn = async (email, password, prefix, displayName) => {
  const newEmail = prefix ? `${prefix}_${email}` : email;
  try {
    await auth().signInWithEmailAndPassword(newEmail, password);
  } catch (e) {
    if (e.code == 'auth/user-not-found') {
      await auth().createUserWithEmailAndPassword(newEmail, password);
      if (displayName) {
        await auth().currentUser.updateProfile({ displayName });
      }
      return;
    }
    /*if (e.code == 'auth/wrong-password' && !email.includes(prefix)) {
      await createOrSignIn(`${prefix}_${email}`, password, prefix, displayName);
      return;
    }
    throw e;
  }
};
*/
const onFacebookButtonPress = async () => {
  let provider = new FacebookAuthProvider();
  provider.addScope('email');
  provider.addScope('public_profile');

  provider.setCustomParameters({
    display: 'popup',
  });
  signInWithPopup(auth, provider).catch((e) => {
    // Handle Errors here.
    console.log('e-->', e.code);
    errors[e.code] && Alert.alert(err, errors[e.code]);
  });
};

const signWithApple = async () => {
  // Start the sign-in request
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');

  signInWithPopup(auth, provider)
    .then((result) => {
      // OAuthProvider.credentialFromResult(result);
    })
    .catch((e) => {
      console.log('e-->', e.code);
      errors[e.code] && Alert.alert(err, errors[e.code]);
      // ...
    });
};

const signWithGoogle = async () => {
  let provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');

  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
    })
    .catch((e) => {
      // Handle Errors here.
      console.log('e-->', e.code);
      errors[e.code] && Alert.alert(err, errors[e.code]);
    });

  /*GoogleSignin.configure({
    webClientId:
      '603068236957-k36qbk5nb50506bptsi0papp7bqi1nkg.apps.googleusercontent.com',
  });

  // Get the users ID token
  try {
    const { idToken } = await GoogleSignin.signIn();

    // reate a Google credential with the token
    const googleCredential = auth.GoogleAuthProvider.credential(idToken);
    // console.log('googleCredential-->', googleCredential);
    // Sign-in the user with the credential
    await auth().signInWithCredential(googleCredential);
  } catch (e) {
    // console.log('e-->', e.code);
    errors[e.code] && Alert.alert(err, errors[e.code]);
  }*/
};

const signWithVK = async (codeVk) => {
  /*
  VKLogin.initialize(7994080);
  */
  /*
  try {
    const authVK = await fetch(
      `https://oauth.vk.com/access_token?client_id=8058578&client_secret=v1f3IE3Pmzi3pQvft3aP&code=${codeVk}&redirect_uri=https://dev.viacheslav.keenetic.link/signin`,

    );

    console.log('authVK  -->', authVK);

    if (authVK) {
      console.log('VK  -->', authVK);
      let displayName;
      if (authVK.access_token) {
        const response = await fetch(
          `https://api.vk.com/method/users.get?v=5.92&fields=photo_100&access_token=${authVK.access_token}`,
        );
        const res = await response.json();
        const {
          response: [{ id, first_name, last_name, photo_100 }],
        } = res;

        displayName = first_name + ' ' + last_name;
      }
      if (!authVK.email) {
        Alert.alert('в учетной записи отсутствует email');
      }

      await createOrSignIn(authVK.email, authVK.user_id, 'VK', displayName);

      // await VKLogin.logout();
    }
  } catch (e) {
    console.log('err->', e);
    errors[e.code] && Alert.alert(err, errors[e.code]);
  } */
};

/*const signWithOk = async () => {
  Odnoklassniki.initialize('512000870784', 'CBIKECKGDIHBABABA');
  Odnoklassniki.login([
    Scopes.VALUABLE_ACCESS,
    Scopes.LONG_ACCESS_TOKEN,
    Scopes.GET_EMAIL,
  ])
    .then((response) => {
      const uid = response?.user?.uid;
      const email = response?.user?.email || `ok${uid}@test.ru`;
      console.log('Odnoklassniki login', response.user);

      auth().createUserWithEmailAndPassword(email, uid);
    })
    .catch((err) => {
      console.log('Login error', err);
    });
  Odnoklassniki.logout();
  // Alert.alert('Err!', e.message);

}; */

export const ButtonsSocial = ({ style, signIn = null, codeVk = '' }) => {
  codeVk && signWithVK(codeVk);
  return (
    <View style={style}>
      {/*<Button
        icon={VkSvg}
        customStyles={[styles.button, styles.buttonVK]}
        titleStyle={styles.buttonTitle}
        iconContainerStyle={styles.iconContainer}
        onPress={() => signWithVK(codeVk)}
        title="Вход через Вконтакте"
      />*/}
      <Button
        icon={FacebookSvg}
        customStyles={[styles.button, styles.buttonFacebook]}
        titleStyle={styles.buttonTitle}
        iconContainerStyle={styles.iconContainer}
        onPress={onFacebookButtonPress}
        title="Вход через Facebook"
      />
      <Button
        icon={AppleSvg}
        customStyles={[styles.button, styles.buttonGoogle]}
        titleStyle={styles.buttonTitle}
        iconContainerStyle={styles.iconContainer}
        onPress={() => signWithApple()}
        title="Вход через Apple"
      />
      <Button
        icon={GoogleSvg}
        customStyles={[styles.button, styles.buttonGoogle]}
        titleStyle={styles.buttonTitle}
        iconContainerStyle={styles.iconContainer}
        onPress={signWithGoogle}
        title="Вход через Google"
      />
      {/*<Button
      icon={OkSvg}
      customStyles={[styles.button, styles.buttonOk]}
      titleStyle={[styles.buttonTitle, styles.buttonTitleOk]}
      iconContainerStyle={styles.iconContainer}
      onPress={() => {} /*signWithOk()}
      title="Через Одноклассники"
    />*/}
    </View>
  );
};
