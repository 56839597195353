import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { bindActionCreators } from 'redux';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import { HeaderProfile } from '@components/organisms/headers';
import * as actions from '@actions';
import { Order } from '@components/modals';
import CodeBox1 from '@images/card-icons/codebox_1.svg';
import CodeBox2 from '@images/card-icons/codebox_2.svg';
import CodeBox3 from '@images/card-icons/codebox_3.svg';
import { CardLinearTemplate } from '@components/templates';
import { styles } from './styles';
import translations from '@translations/ru.json';

const texts = translations.order;

const MainMenuView = ({
  navigation,
  displayName = '',
  sendMessageState,
  sendMessage,
}) => {
  const [visible, setVisible] = useState(false);
  const [theme, setTheme] = useState('');
  const [website, setWebsite] = useState('');
  const [fontSizeTitle, setFontSizeTitle] = useState(22);
  const [isMobile, setIsMobile] = useState(false);
  const linkTo = useLinkTo();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    //-- меняем размер шрифта если меняется ширина, не больше 22
    const newFontSize = (width * 22) / 1640;
    const isSmall = width < 1070;
    newFontSize <= 22 && setFontSizeTitle(isSmall ? 16 : newFontSize);
    setIsMobile(isSmall);
  }, [width]);

  return (
    <View style={[styles.root, { minHeight: 500 }]}>
      <Order
        visible={visible}
        onToggle={() => setVisible(false)}
        theme={theme}
        website={website}
        sendMessage={sendMessage}
        sendMessageState={sendMessageState}
      />
      <HeaderProfile welcome details />
      <View
        style={[styles.body, isMobile ? { flexDirection: 'colummn' } : { flexDirection: 'row', alignItems: 'flex-start' }]}
      >
        <CardLinearTemplate
          style={[
            styles.cardContainer,
            styles.testContainer,
            isMobile ? styles.maxWidth : styles.cardFlex,
          ]}
          colors={['#54B58F', '#54B58F']}
          onPress={() => {
            linkTo('/test');
            /*navigation.navigate('TestStackScreen')*/
          }}
        >
          <View>
            <Text style={[styles.titleTest, { fontSize: fontSizeTitle }]}>
              Тесты
            </Text>
            <Text style={[styles.titleTest, { fontSize: fontSizeTitle - 6 }]}>
              Проверьте свои знания
            </Text>
          </View>
          <View style={styles.iconContainer}>
            <CodeBox3 />
          </View>
        </CardLinearTemplate>
        <CardLinearTemplate
          style={[
            styles.cardContainer,
            isMobile ? styles.maxWidth : styles.cardFlex,
          ]}
          colors={['#29D890', '#4FAA9F']}
          onPress={() => {
            setTheme(texts.expertise);
            setWebsite('https://psstver.ru');
            setVisible(true);
          }}
        >
          <Text style={[styles.titleTest, { fontSize: fontSizeTitle }]}>
            {texts.expertise}
          </Text>
          <View style={styles.iconContainer}>
            <CodeBox2 />
          </View>
        </CardLinearTemplate>
        <CardLinearTemplate
          style={[
            styles.cardContainer,
            isMobile ? styles.maxWidth : styles.cardFlex,
          ]}
          colors={['#29D890', '#4FAA9F']}
          onPress={() => {
            setTheme(texts.development);
            setWebsite('https://psstver.com');
            setVisible(true);
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={[styles.titleTest, { fontSize: fontSizeTitle }]}>
              {texts.development}
            </Text>
          </View>
          <View style={styles.iconContainer}>
            <CodeBox1 />
          </View>
        </CardLinearTemplate>
      </View>
    </View>
  );
};

const mapStateToProps = ({ user: { displayName }, sendMessageState }) => ({
  displayName,
  sendMessageState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendMessage: actions.sendMessage,
    },
    dispatch,
  );

export const MainMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMenuView);
