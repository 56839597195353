import React from 'react';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import translations from '@translations/ru.json';
import { styles } from './styles';

const texts = translations.common;

export const Politics = ({ style }) => {
  const navigation = useNavigation();

  return (
    <View style={style}>
      <Text style={styles.politics}>
        {texts.politics.one}
        <TouchableWithoutFeedback
          onPress={() => navigation.navigate('Privacy')}
        >
          <Text style={styles.link}>{texts.politics.two}</Text>
        </TouchableWithoutFeedback>
        .
      </Text>
    </View>
  );
};
