import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  grid: {
    margin: 0,
    borderRadius: 8,
    backgroundColor: '#F6F6F6',
    padding: 20,
    minHeight: 60,
  },
});
