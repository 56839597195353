import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import CloseWhiteIcon from '@images/icons/close-white.png';
import { styles } from './styles';


export const CloseBtn = ({ navigation }) => (
  <TouchableOpacity
    style={styles.close}
    activeOpacity={0.8}
    onPress={() => navigation.goBack()}
  >
    <Image source={CloseWhiteIcon} style={styles.closeIcon} />
  </TouchableOpacity>
);
