import React from 'react';
import { ImageBackground, TouchableOpacity, View, Text } from 'react-native';
import { connect } from 'react-redux';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { LastUpdateDate } from '@components/atoms';
import imageProfile from '@images/profile-header.png';
import Menu from '@images/icons/menu.svg';
import { styles } from './styles';

const Header = ({ displayName = '', welcome, style }) => {
  const navigation = useNavigation();

  const { width } = useWindowDimensions();

  return (
    <ImageBackground
      source={imageProfile}
      style={[
        styles.image,
        style,
        { flexDirection: 'row', justifyContent: 'space-between' },
      ]}
    >
      <View>
        {width < 1070 && (
          <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Menu />
          </TouchableOpacity>
        )}
        <Text style={styles.text}>{displayName} </Text>
        {welcome && (
          <Text style={styles.title}>Добро пожаловать в приложение</Text>
        )}
      </View>
      {!(width < 1070) && (
        <LastUpdateDate style={[styles.title, styles.details]} />
      )}
    </ImageBackground>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogin: !!user.id,
  displayName: user.displayName,
});

export const HeaderProfile = connect(mapStateToProps)(Header);
