import React, { useState } from 'react';
import { View, Text, Modal, TouchableOpacity } from 'react-native';
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';

import { ModalTemplate } from '@components/templates';
import { Button } from '@components/atoms';
import { styles } from './styles';
import Close from '@images/icons/close-gray.svg';
import StarStruck from '@images/icons/star-struck.svg';
import { selectMustShowDonate } from '@selectors/donate';
import * as actions from '@actions';

export const DonateCTA = ({ immediatelyAfterTheCountdown }) => {
  const navigation = useNavigation();
  const { name, params } = useRoute();
  const dispatch = useDispatch();

  const mustShowDonate = useSelector(selectMustShowDonate);

  const [visible, setVisible] = useState(false);

  const close = () => {
    setVisible(false);
    dispatch(actions.hideDonate());
  };

  const onClickDonate = () => {
    close();
    navigation.navigate('DonateScreen', {
      redirectRoute: { name, params },
    });
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (mustShowDonate) {
        setVisible(true);
      }
    });

    return unsubscribe;
  }, [navigation, mustShowDonate]);

  useFocusEffect(
    React.useCallback(() => {
      if (immediatelyAfterTheCountdown && mustShowDonate) {
        setVisible(true);
      }
    }, [immediatelyAfterTheCountdown, mustShowDonate]),
  );

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent
      onRequestClose={close}
    >
      <ModalTemplate rootStyles={styles.backstage}>
        <View style={styles.modalContainer}>
          <TouchableOpacity onPress={close} style={styles.closeIcon}>
            <Close />
          </TouchableOpacity>
          <StarStruck />
          <Text style={styles.text}>
            Вам нравится приложение? {'\n'}
            Поддержите нас{'\n'}в знак благодарности!
          </Text>

          <Button
            onPress={onClickDonate}
            title="Поддержать"
            customStyles={styles.btn}
          />
        </View>
      </ModalTemplate>
    </Modal>
  );
};
