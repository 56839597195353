import React, { useState } from 'react';
import { Text, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import messaging from '@react-native-firebase/messaging';
import { Input, Button } from '@components/atoms';
import { AuthTemplate } from '@components/templates';
import { DeleteInstruction, ButtonsSocial } from '@components/organisms';
// import * as actions from '@actions';
// import translations from '@translations/ru.json';
import { styles } from './styles';
// import auth from '@react-native-firebase/auth';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
// import '../../index';

const onChangeField =
  (field, error, changeFunction, clearFunction) => (value) => {
    if (error) {
      clearFunction('');
    }

    changeFunction(value);
  };

const errors = {
  'auth/user-not-found': 'пользователь не найден',
  'auth/invalid-email': 'некорректный email',
  'auth/wrong-password': 'неверный пароль',
  'auth/too-many-requests': 'слишком много запросов, попробуйти позже',
  'auth/network-request-failed': 'технические проблемы, попробуйте позже',
};

const SignInView = ({ navigation, route }) => {
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [error, setSignInError] = useState('');
  const [isFetching, setFetching] = useState(false);

  const code  = route?.params?.code ;
  code && console.log('params->', code);

  return (
    <AuthTemplate>
      <Input
        customStyle={styles.input}
        value={email}
        onChangeText={onChangeField(
          'email',
          error,
          changeEmail,
          setSignInError,
        )}
        error={null}
        placeholder="Эл. почта"
        icon="email"
        dataDetectorTypes="address"
        keyboardType="email-address"
        textContentType="emailAddress"
      />
      <Input
        customStyle={[styles.input, styles.inputMargin]}
        value={password}
        onChangeText={onChangeField(
          'password',
          error,
          changePassword,
          setSignInError,
        )}
        error={error}
        placeholder="Пароль"
        type="password"
        secureTextEntry
      />
      <ButtonsSocial style={styles.buttonsSocial} signIn codeVk={code}/>
      <DeleteInstruction />
      <Button
        customStyles={styles.btn}
        title="Войти"
        titleStyle={styles.titleButton}
        onPress={() => {
          setFetching(true);
          signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              setFetching(false);
              console.log('User account signed in!');
            })
            .catch((error) => {
              setFetching(false);
              console.log('err-->', error.code);
              setSignInError(errors[error.code]);
            });
        }}
        disabled={!email || !password}
        isFetching={isFetching}
      />
      <Button
        onPress={() => navigation.navigate('ResetPassword')}
        light
        customStyles={[styles.btn, styles.btnSignUp]}
        title="Забыли пароль?"
      />
    </AuthTemplate>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInView);
