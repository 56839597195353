import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    backgroundColor: '#fff',
    flex: 1,
  },
  body: {
    paddingTop: 80,
    paddingBottom: 50,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 25,
  },
  text: {
    fontSize: 14,
    lineHeight: 25,
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});
