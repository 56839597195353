import React, { useState } from 'react';
import { View, TextInput, Text, TouchableOpacity } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import EyeOffIcon from '@images/icons/eye-off.svg';
import { styles } from './styles';

export const Input = ({
  customStyle,
  customStyleInput = {},
  error = '',
  icon = '',
  type,
  disabled = false,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <View style={[styles.root, customStyle]}>
      <View style={[styles.inputWrapper, disabled ? styles.disabled : {}]}>
        {type === 'phone' ? (
          <TextInputMask
            style={[styles.input, customStyleInput]}
            placeholderTextColor="#BDBDBD"
            type="custom"
            options={{
              mask: '+9 (999) 999 99 99',
              validator: function (value, settings) {
                return false;
              },
            }}
            disabled={disabled}
            {...props}
          />
        ) : (
          <TextInput
            style={[styles.input, customStyleInput]}
            placeholderTextColor="#BDBDBD"
            disabled={disabled}
            {...props}
            secureTextEntry={type === 'password' ? !passwordVisible : false}
          />
        )}
        {type === 'password' && (
          <TouchableOpacity
            style={styles.icon}
            onPress={() => setPasswordVisible(!passwordVisible)}
          >
            <EyeOffIcon />
          </TouchableOpacity>
        )}
      </View>
      <Text style={styles.error}>{error}</Text>
    </View>
  );
};
