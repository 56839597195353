import React from 'react';
import { TouchableOpacity } from 'react-native';

import { styles } from './styles';
import Close from '@images/icons/close-gray.svg';

export const ModalCloseIcon = ({ onClose, style }) => (
  <TouchableOpacity onPress={onClose} style={[styles.closeIcon, style]}>
    <Close />
  </TouchableOpacity>
);
