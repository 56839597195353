import { StyleSheet, Platform } from 'react-native';

const styleBody = Platform.select({
  ios: { flex: 1 },
  android: { minHeight: '100%', flex: 1 },
});

export const styles = StyleSheet.create({
  modalRootStyles: {
    alignItems: 'stretch',
  },
  body: {
    ...styleBody,
    justifyContent: 'center',
  },
  modalContainer: {
    ...Platform.select({
      ios: {},
      android: {},
      default: {
        maxWidth: 500,
        width: '100%',
      },
    }),
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 25,
    padding: 15,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
  },
  title: {
    marginTop: 15,
    marginBottom: 17,
  },
  formScrollWrapper: {
    width: '100%',
  },
});
