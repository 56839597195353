import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    padding: 20,
    backgroundColor: '#4FAA9F',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
