import React, { useState } from 'react';
import {
  View,
  Platform,
  Text,
  Modal,
  TouchableOpacity,
  Linking,
  Alert,
} from 'react-native';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Communications from 'react-native-communications';
import { ModalTemplate } from '@components/templates';
import { Input, Button } from '@components/atoms';
import { s } from './styles';
import Close from '@images/icons/close-gray.svg';
import translations from '@translations/ru.json';

const texts = translations.order;

export const Order = ({
  visible,
  onToggle,
  onPressMode,
  sendMessage,
  sendMessageState,
  theme,
  website = 'https://psstver.ru',
}) => {
  const [name, changeName] = useState('');
  const [phone, changePhone] = useState('');
  const [message, changeMessage] = useState('');

  const linkHandle = () => {
    Linking.canOpenURL(website).then((res) => {
      res && Linking.openURL(website);
    });
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent
      onRequestClose={onToggle}
    >
      <ModalTemplate>
        <View style={s.modalContainer}>
          <TouchableOpacity onPress={() => onToggle()} style={s.icon}>
            <Close />
          </TouchableOpacity>
          <Text style={s.title}>Свяжитесь с нами</Text>
          <View style={s.detail}>
            <Text style={s.detailTitle}>
              Tел: 8 (800) 550-33-16{'\n'}
              Эл. почта: <Text>psstver@mail.ru</Text>
              {'\n'}
              Cайт:{' '}
              <Text href={website} style={s.website}>
                {website}
              </Text>
              {'\n'}
            </Text>
          </View>
          <View style={s.inputContainer}>
            <Input
              customStyle={s.input}
              value={name}
              onChangeText={changeName}
              placeholder={texts.name}
              error={null}
            />
            <Input
              customStyle={s.input}
              value={phone}
              onChangeText={changePhone}
              placeholder={texts.phone}
              error={null}
            />
            <Input
              customStyle={s.inputMessageContainer}
              customStyleInput={s.inputMessage}
              value={message}
              onChangeText={changeMessage}
              placeholder={texts.message}
              numberOfLines={5}
              multiline
              error={null}
            />
          </View>
          <Text style={[s.detailTitle, s.detailTitle2]}>
            Обращаем Ваше внимание, компания ПромСтройСервис работает на
            территории Центрального и Северо-Западного федеральных округов.
          </Text>
          <Button
            onPress={() => {
              sendMessage(
                { name, phone, comment: message, expertiseType: theme },
                (isSuccess, response) => {
                  if (isSuccess && response.data.success) {
                    changeMessage('');
                    alert('Сообщение отправлено!');
                  } else {
                    alert('Ошибка!\nТехнические проблемы, попробуйте позже');
                  }
                  onToggle();
                },
              );
            }}
            title="Отправить"
            disabled={!phone || !message}
            customStyles={s.btn}
            isFetching={sendMessageState.isFetching}
          />
        </View>
      </ModalTemplate>
    </Modal>
  );
};
