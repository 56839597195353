import React from 'react';
import { Image } from 'react-native';
import { SvgXml } from 'react-native-svg';
import { styles } from './styles';

export default function Test({ color = '#BDBDBD' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.074 0.696838H3.07397C2.54354 0.696838 2.03483 0.907552 1.65976 1.28262C1.28469 1.6577 1.07397 2.16641 1.07397 2.69684V18.6968C1.07397 19.2273 1.28469 19.736 1.65976 20.1111C2.03483 20.4861 2.54354 20.6968 3.07397 20.6968H15.074C15.6044 20.6968 16.1131 20.4861 16.4882 20.1111C16.8633 19.736 17.074 19.2273 17.074 18.6968V6.69684M11.074 0.696838L17.074 6.69684M11.074 0.696838V6.69684H17.074M13.074 11.6968H5.07397M13.074 15.6968H5.07397M7.07397 7.69684H5.07397"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
