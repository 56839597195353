import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GridTemplate } from '@components/templates';
import { ModeFlow } from '@components/modals/mode-flow';
import { CardLinearTemplate } from '@components/templates';
import { HeaderProfile, HeaderTestFlow } from '@components/organisms/headers';
import * as actions from '@actions';
import tests from '@data/tests.js';
import theme from '@const/theme';
import { TestCard } from '@components/organisms';
import { styles } from './styles';

const TestsView = ({
  favourites,
  route,
  navigation,
  resetResults,
  testResults,
}) => {
  const { blocks, screen, id } = route?.params;
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 800);
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState({});
  const [currentResults, setCurrentResults] = useState([]);
  const [hasTestResults, setHasTestResults] = useState(false);

  console.log('state->', navigation.getState());

  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  const pressMode = (mode, reset) => {
    reset && resetResults(params?.code);
    navigation.navigate('Questions', { ...params, mode });
    setTimeout(() => setVisible(false), 200);
  };

  const onPressHandler = (item) => {
    setCurrentResults(testResults[item.code]);
    setVisible(true);
    setParams({
      id,
      code: item.code,
      name: `Блок ${item.title}`,
      title: item.title,
      description: item.description,
    });
  };

  return (
    <GridTemplate style={[styles.root, { minHeight: 500 }]}>
      <ModeFlow
        visible={visible}
        onToggle={() => setVisible(false)}
        onPressMode={pressMode}
        testResults={currentResults}
      />
      <HeaderTestFlow leftTitles={['Тесты']} />
      <ScrollView
        style={styles.body}
        contentContainerStyle={styles.scrollViewContainer}
        showsVerticalScrollIndicator={false}
      >
        {!!favourites.length && screen == 1 && (
          <CardLinearTemplate
            styleContainer={[{ flexDirection: 'column' }]}
            style={[
              styles.gradientContainer,
              isMobile ? { alignSelf: 'stretch' } : { width: 500 },
            ]}
            colors={['#29D890', '#4FAA9F']}
            onPress={() => pressMode('favourites')}
          >
            <Text style={styles.titleFavoriteTest}>Тест по Избранному</Text>
            <Text style={[styles.titleFavoriteTest, styles.titleDetail]}>
              Тестирование по вопросам в Избранном
            </Text>
          </CardLinearTemplate>
        )}
        {blocks.sort().map((item) => {
          return (
            <TestCard
              key={item.id}
              screen={screen}
              item={item}
              id={id}
              onPressHandler={onPressHandler}
            />
            /*<TouchableOpacity
            key={item.id || item.code}
            style={[
              styles.testContainer,
              isMobile ? { alignSelf: 'stretch' } : { width: 500 },
            ]}
            onPress={() => {
              screen > 1
                ? onPressHandler(item)
                : navigation.push('Tests', {
                    blocks: tests[item.id],
                    screen: screen + 1,
                    id: item.id,
                    name: item.title,
                  });
            }}
          >
            <Text style={styles.titleTest}>{item.title}</Text>
            <Text style={[styles.titleTest, styles.titleDetail]}>
              {item.description}
            </Text>
          </TouchableOpacity>*/
          );
        })}
      </ScrollView>
    </GridTemplate>
  );
};

const mapStateToProps = ({ favourites, results }) => ({
  testResults: results,
  favourites,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetResults: actions.resetResults,
    },
    dispatch,
  );

export const Tests = connect(mapStateToProps, mapDispatchToProps)(TestsView);
