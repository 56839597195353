import { StyleSheet, Platform } from 'react-native';

const styleBody = Platform.select({
  ios: { flex: 1 },
  android: { minHeight: '100%', flex: 1 },
});

export const s = StyleSheet.create({
  root: {
    alignContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    marginTop: 30,
    padding: 15,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    // alignSelf: 'stretch',
    alignItems: 'center',
    maxWidth: 500,
  },
  body: {
    ...styleBody,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 25,
    fontSize: 24,
    fontWeight: 'bold',
  },
  detail: {
    marginBottom: 0,
    alignSelf: 'stretch',
  },
  detailTitle: {
    textAlign: 'left',
    marginTop: 20,
    paddingHorizontal: 15,
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'normal',
  },
  detailTitle2: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 19,
  },
  website: {
    color: '#4FAA9F',
  },
  inputContainer: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    marginTop: 0,
    marginBottom: 0,
  },
  inputMessageContainer: {
    height: 120,
    // fontWeight: 'normal',
  },
  inputMessage: {
    height: 120,
    fontSize: 16,
    paddingTop: 10
    // fontWeight: 'normal',
  },
  btns: {
    width: '100%',
    marginTop: 25,
    justifyContent: 'center',
  },
  btn: {
    marginTop: 20,
    marginBottom: 15,
    width: '100%',
  },
  btnDisable: {
    backgroundColor: '#BDBDBD',
  },
});
