import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Switch,
  ScrollView,
  View,
  Pressable,
  Text,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { GridTemplate } from '@components/templates';
import { HeaderProfile } from '@components/organisms/headers';
import { Button } from '@components/atoms';
import { CardTemplate } from '@components/templates';
import Minus from '@images/icons/minus.svg';
import Plus from '@images/icons/plus.svg';
import * as actions from '@actions';
import { CommonActions } from '@react-navigation/native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import * as Progress from 'react-native-progress';
import { signOut } from 'firebase/auth';
import { styles } from './styles';
// import messaging from '@react-native-firebase/messaging';

const ProfileView = ({
  email,
  displayName,
  total,
  success,
  addUserData,
  providerData,
  fontSize,
  mixAnswers,
  setSettings,
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const value = Math.round((success / total) * 100);
    const result = isFinite(value) ? value : 0;
    setProgressValue(result);
  }, [total, success]);

  const [isMobile, setIsMobile] = useState(width < 1070);

  useEffect(() => {
    if (width < 1070) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <View style={styles.root}>
      <HeaderProfile welcome details />
      <CardTemplate
        style={[
          styles.progressCard,
          isMobile
            ? { alignSelf: 'stretch' }
            : { width: 600, alignSelf: 'center' },
        ]}
      >
        <View style={styles.progressRow}>
          <Text style={styles.progressTitle}>Пройдено экзаменов</Text>
          <Text style={styles.progressTitle}>
            {success}/{total}
          </Text>
        </View>
        <View style={styles.progressBarContainer}>
          <ProgressBarAnimated
            borderWidth={0}
            width={isMobile ? width - 70 : width - 1980}
            value={progressValue}
            backgroundColor="#29D890"
            baseBackgroundColor="#fff"
            underlyingColor="#fff"
            backgroundColorOnComplete="#6CC644"
          />
        </View>
        <Button
          customStyles={styles.btnReset}
          title="Сбросить прогресс"
          light
          onPress={() => addUserData({ progress: { success: 0, total: 0 } })}
          disabled={false}
          isFetching={false}
        />
      </CardTemplate>
      <GridTemplate
        style={[
          styles.mainContainer,
          isMobile
            ? { alignSelf: 'stretch' }
            : { width: 600, alignSelf: 'center' },
        ]}
      >
        <View style={styles.bodyContainer}>
          <Text style={styles.personalDataTitle}>Личные данные</Text>
          <CardTemplate style={styles.cardContainer}>
            <View style={styles.rowName}>
              <Text style={styles.dataTitle}>
                {displayName || 'Имя Фамилия'}
              </Text>
            </View>
            <View style={[styles.rowName, styles.rowEmail]}>
              <Text style={styles.dataTitle}>
                {email || providerData[0].email}
              </Text>
            </View>
          </CardTemplate>
        </View>
        <Button
          customStyles={styles.btn}
          title="Выход"
          onPress={() => {
            signOut(auth)
              .then(() => {
                console.log('User signed out!');
              })
              .catch((error) => {
                console.log('Error sign out -> ', error.message);
              });
          }}
          disabled={false}
          isFetching={false}
        />
      </GridTemplate>
    </View>
  );
};

const mapStateToProps = ({
  user: {
    email,
    displayName,
    progress: { success, total },
    providerData,
  },
  settings: { fontSize, mixAnswers },
}) => ({
  email,
  displayName,
  success,
  total,
  providerData,
  fontSize,
  mixAnswers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUserData: actions.addUserData,
      resetData: actions.resetData,
      setSettings: actions.setSettings,
    },
    dispatch,
  );

export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileView);
