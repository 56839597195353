import React from 'react';
import { SafeAreaView, ScrollView, Text } from 'react-native';
import translations from '@translations/ru.json';
import theme from '@const/theme';
import { styles } from './styles';

const texts = translations.agreement;

export const Agreement = () => (
  <SafeAreaView style={styles.root}>
    <ScrollView
      contentContainerStyle={styles.body}
      indicatorStyle={theme.indicatorStyle}
    >
      <Text style={styles.title}>{texts.title}</Text>
      <Text style={styles.text}>{texts.texts}</Text>
    </ScrollView>
  </SafeAreaView>
);
