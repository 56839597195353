import { actionLostInternet } from '@actions';

export const promises = (store) => (next) => (action) => {
  const { payload } = action;

  if (!payload || !action.payload.isPromise) {
    return next(action);
  }

  const { request, success, failure } = payload.actions;
  const { params } = payload;
  const promise = payload.promise();
  store.dispatch({ type: request });

  promise.then(
    (data) => {
      store.dispatch({ type: success, data, params });
      payload.callback && payload.callback(true, data);
    },
    (error) => {
      // TODO: может есть другая проверка на отсутствие интернета?
      /* 
      if (!error.response) {
        store.dispatch({ type: actionLostInternet });
      }
      */
      store.dispatch({ type: failure, error, params });
      payload.callback && payload.callback(false, error);
    },
  );
};
