import React from 'react';
import { Text } from 'react-native';

import { styles } from './styles';

export const ModalTitle = ({ children, style, ...rest }) => (
  <Text style={[styles.title, style]} {...rest}>
    {children}
  </Text>
);
