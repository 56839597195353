import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  linearCard: {
    borderRadius: 8,
  },
  container: {
    //maxWidth: 400,
    width: '100%',
    borderRadius: 8,
    padding: 20,
    paddingRight: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
