import React from 'react';
import { Text, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  text: {
    marginBottom: 5,
    color: 'red',
  },
});

export const Deprecated = () => (
  <Text style={styles.text}>Устаревшее! (можно удалить свайпом влево)</Text>
);
