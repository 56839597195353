import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 30,
    lineHeight: 36,
    textAlign: 'center',
    color: '#111',
  },
});
