import { StyleSheet } from 'react-native';

export const s = StyleSheet.create({
  root: {
    alignContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    marginTop: 0,
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 25,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    alignSelf: 'center',
    alignItems: 'center',
    width: 600,
  },
  icon: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 25,
    fontSize: 26,
    fontWeight: 'bold',
  },
  context: {
    marginTop: 0,
    fontSize: 12,
    textAlign: 'center',
  },
  btns: {
    marginTop: 25,
  },
  btn: {
    maxWidth: 280,
    width: 280,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#4FAA9F',
  },
  resetContainer: {
    alignSelf: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  btnReset: {
    height: 39,
    width: 200,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: '#2AD1A9',
  },
  image: {
    width: 30,
    height: 30,
  },
});
