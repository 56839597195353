import React from 'react';
// import { SvgXml } from 'react-native-svg';

export default function Close({ color = '#BDBDBD', fill = 'none' }) {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="7"
        y1="16.3649"
        x2="16.9506"
        y2="6.41432"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="16.9506"
        y1="16.3137"
        x2="7.00001"
        y2="6.3631"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );

  // return <SvgXml xml={xml} />;
}
