import React from 'react';
import { SafeAreaView, ScrollView, Text, Linking } from 'react-native';
import translations from '@translations/ru.json';
import theme from '@const/theme';
import { styles } from './styles';

const texts = translations.delete_account;

const email = 'app@psstver.ru';
const openEmailClient = () => {
  Linking.openURL(`mailto:${email}`);
};

export const DeleteAccount = () => (
  <SafeAreaView style={styles.root}>
    <ScrollView
      contentContainerStyle={styles.body}
      indicatorStyle={theme.indicatorStyle}
    >
      <Text style={styles.title}>{texts.title}</Text>

      <Text style={styles.text}>
        {texts.texts.text_1}
        <Text style={styles.link} onPress={openEmailClient}>
          {email}
        </Text>
        {texts.texts.text_2}
      </Text>
    </ScrollView>
  </SafeAreaView>
);
