import React, { useState, useEffect } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { HeaderTestFlow, HeaderProfile } from '@components/organisms/headers';

import { FindViewContent } from './content';
import { styles } from './styles';

export const Find = () => {
  const { width, height } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 800);

  // -- если поменялась ширина экрана
  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  return (
    <View style={styles.root}>
      <HeaderProfile welcome />
      <HeaderTestFlow leftTitles={['Поиск']} />`
      <View
        style={[
          { alignSelf: 'center', height: height - 180 },
          isMobile
            ? { alignSelf: 'stretch', paddingHorizontal: 20 }
            : { alignSelf: 'center', width: 600 },
        ]}
      >
        <FindViewContent />
      </View>
    </View>
  );
};
