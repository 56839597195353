import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    position: 'relative',
  },
  image: {
    height: 24,
    width: 24,
    
  },
});

