import React, { useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import SplashScreen from 'react-native-splash-screen';
// import NetInfo from '@react-native-community/netinfo';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import * as actions from '@actions';
import { Main } from './screens/main/navigation';

// import queryString from 'query-string';
import {
  FirstScreen,
  SignUp,
  SignIn,
  // MainMenu,
  ResetPassword,
  DeleteAccount,
  Privacy,
} from './screens';
// import { instance } from './store/api';

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyD8jXGcxCIeG6xLaezF2FcOtHcdjk2eBg0',
  authDomain: 'insaqu-39479.firebaseapp.com',
  projectId: 'insaqu-39479',
  storageBucket: 'insaqu-39479.appspot.com',
  messagingSenderId: '603068236957',
  appId: '1:603068236957:web:b41bd4d08d144ff59b4074',
  measurementId: 'G-DE7D1Y8LW6',
};

initializeApp(firebaseConfig);
global.auth = getAuth();

const Stack = createStackNavigator();
const { Navigator, Screen } = Stack;

const navigationRef = createRef();

const App = ({ setUserData, hasUid }) => {
  function onStateChanged(user) {
    // console.log('user->', user);
    setUserData(user);
  }

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, onStateChanged);
    return unsubscriber; // unsubscribe on unmount
  }, []);

  return (
    <NavigationContainer
      linking={{
        //prefixes: ['http://dev.viacheslav.keenetic.link', 'localhost'],
        config: {
          screens: {
            SignIn: 'signin',
            SignUp: 'signup',
            FirstScreen: 'firstScreen',
            ResetPassword: 'resetpassword',
            DeleteAccount: 'delete-account',
            Main: {
              screens: {
                MainMenu: 'mainmenu',
                TestStackScreen: 'test',
                Profile: 'profile',
                FavouritesStackScreen: {
                  screens: { Favourites: 'favourites' },
                },
              },
            },
          },
        },
      }}
      ref={navigationRef}
    >
      <Navigator headerMode="screen" /*initialRouteName="FirstScreen"*/>
        {!hasUid ? (
          <>
            <Screen
              name="FirstScreen"
              component={FirstScreen}
              options={{
                headerShown: false,
              }}
            />
            <Screen
              name="SignIn"
              component={SignIn}
              options={{
                headerShown: true,
                headerTransparent: false,
                headerStyle: {
                  // height: 100,
                  borderBottomWidth: 0,
                },
                headerTitleStyle: {
                  fontSize: 26,
                  fontWeight: 'bold',
                },
                title: 'Вход',
                headerTitleAlign: 'center',
                headerShadowVisible: true,
              }}
            />
            <Screen
              name="SignUp"
              component={SignUp}
              options={{
                headerShown: true,
                headerTransparent: false,
                headerStyle: {
                  // height: 100,
                  borderBottomWidth: 0,
                },
                headerTitleStyle: {
                  fontSize: 26,
                  fontWeight: 'bold',
                },
                title: 'Регистрация',
                headerTitleAlign: 'center',
                headerShadowVisible: false,
              }}
            />
            <Screen
              name="ResetPassword"
              component={ResetPassword}
              options={{
                headerShown: true,
                headerTransparent: false,
                headerStyle: {
                  // height: 100,
                  borderBottomWidth: 0,
                },
                headerTitleStyle: {
                  fontSize: 26,
                  fontWeight: 'bold',
                },
                title: 'сброс пароля',
                headerTitleAlign: 'center',
                headerShadowVisible: false,
              }}
            />
          </>
        ) : (
          <Screen
            name="Main"
            component={Main}
            options={{
              headerShown: false,
            }}
          />
        )}
        <Screen
          name="Privacy"
          component={Privacy}
          options={({ navigation, route }) => ({
            headerBackVisible: false,
            headerStyle: { backgroundColor: 'transparent' },
            headerTransparent: true,
            headerTitle: '',
            headerShadowVisible: false,
          })}
        />
        <Screen
          name="DeleteAccount"
          component={DeleteAccount}
          options={({ navigation, route }) => ({
            headerBackVisible: false,
            headerStyle: { backgroundColor: 'transparent' },
            headerTransparent: true,
            headerTitle: '',
            headerShadowVisible: false,
          })}
        />
      </Navigator>
    </NavigationContainer>
  );
};

const mapStateToProps = ({
  // openedScreenNoInternet,
  user: { uid },
}) => ({
  // openedScreenNoInternet,
  hasUid: !!uid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // closeScreenNoInternet: actions.actionCloseScreenNoInternet,
      setUserData: actions.setUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
