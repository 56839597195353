export default {
  0: [
    {
      code: 63627,
      title: 'А.1',
      description:
        'Подготовка по области аттестации А.1 руководителей и специалистов организаций по основам промышленной безопасности',
    },
  ],
  1: [
    {
      code: 63629,
      title: 'Б.1.1.',
      description:
        'Подготовка по области аттестации Б.1.1 руководителей и специалистов организаций, эксплуатирующих химически опасные производственные объекты',
    },
    {
      code: 63630,
      title: 'Б.1.2.',
      description:
        'Подготовка по области аттестации Б.1.2 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты нефтегазоперерабатывающих и нефтехимических производств',
    },
    {
      code: 63631,
      title: 'Б.1.3.',
      description:
        'Подготовка по области аттестации Б.1.3 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты сжиженного природного газа',
    },
    {
      code: 63632,
      title: 'Б.1.4.',
      description:
        'Подготовка по области аттестации Б.1.4 руководителей и специалистов организаций, эксплуатирующих хлорные объекты',
    },
    {
      code: 63633,
      title: 'Б.1.5.',
      description:
        'Подготовка по области аттестации Б.1.5 руководителей и специалистов организаций, эксплуатирующих производства минеральных удобрений',
    },
    {
      code: 63634,
      title: 'Б.1.6.',
      description:
        'Подготовка по области аттестации Б.1.6 руководителей и специалистов организаций, эксплуатирующих аммиачные холодильные установки',
    },
    {
      code: 63635,
      title: 'Б.1.7.',
      description:
        'Подготовка по области аттестации Б.1.7 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты складов нефти и нефтепродуктов',
    },
    {
      code: 63636,
      title: 'Б.1.8.',
      description:
        'Подготовка по области аттестации Б.1.8 руководителей и специалистов организаций, осуществляющих проектирование химически опасных производственных объектов',
    },
    {
      code: 63637,
      title: 'Б.1.9.',
      description:
        'Подготовка по области аттестации Б.1.9 руководителей и специалистов организаций, осуществляющих строительство, реконструкцию, техническое перевооружение, капитальный ремонт, консервацию и ликвидацию химически опасных производственных объектов',
    },
    {
      code: 63638,
      title: 'Б.1.10.',
      description:
        'Подготовка по области аттестации Б.1.10 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение, капитальный ремонт, консервацию и ликвидацию опасных производственных объектов нефтегазоперерабатывающих и нефтехимических производств',
    },
    {
      code: 63639,
      title: 'Б.1.11.',
      description:
        'Подготовка по области аттестации Б.1.11 руководителей и специалистов организаций, отвечающих за безопасное ведение газоопасных, огневых и ремонтных работ',
    },
    {
      code: 63640,
      title: 'Б.1.12.',
      description:
        'Подготовка по области аттестации Б.1.12 руководителей и специалистов организаций, эксплуатирующих компрессорные установки с поршневыми компрессорами, работающими на взрывоопасных и вредных газах',
    },
    {
      code: 63641,
      title: 'Б.1.13.',
      description:
        'Подготовка по области аттестации Б.1.13 руководителей и специалистов организаций, эксплуатирующих стационарные компрессорные установки, воздухопроводы и газопроводы',
    },
    {
      code: 63642,
      title: 'Б.1.14.',
      description:
        'Подготовка по области аттестации Б.1.14 руководителей и специалистов организаций, осуществляющих производство водорода методом электролиза воды',
    },
    {
      code: 63643,
      title: 'Б.1.15.',
      description:
        'Подготовка по области аттестации Б.1.15 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых осуществляются технологические процессы нитрования',
    },
    {
      code: 63644,
      title: 'Б.1.16.',
      description:
        'Подготовка по области аттестации Б.1.16 руководителей и специалистов организаций, эксплуатирующих объекты маслоэкстракционных производств и производств гидрогенизации жиров',
    },
    {
      code: 63645,
      title: 'Б.1.17.',
      description:
        'Подготовка по области аттестации Б.1.17 руководителей и специалистов организаций, эксплуатирующих объекты производства и потребления продуктов разделения воздуха',
    },
    {
      code: 63646,
      title: 'Б.1.18.',
      description:
        'Подготовка по области аттестации Б.1.18 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты производства шин, резинотехнических и латексных изделий',
    },
  ],
  2: [
    {
      code: 63648,
      title: 'Б.2.1.',
      description:
        'Подготовка по области аттестации Б.2.1 руководителей и специалистов организаций, эксплуатирующих объекты нефтяной и газовой промышленности',
    },
    {
      code: 63649,
      title: 'Б.2.2.',
      description:
        'Подготовка по области аттестации Б.2.2 руководителей и специалистов организаций, осуществляющих ремонт нефтяных и газовых скважин',
    },
    {
      code: 63650,
      title: 'Б.2.3.',
      description:
        'Подготовка по области аттестации Б.2.3 руководителей и специалистов организаций, осуществляющих проектирование объектов нефтегазодобычи',
    },
    {
      code: 63651,
      title: 'Б.2.4.',
      description:
        'Подготовка по области аттестации Б.2.4 руководителей и специалистов организаций, осуществляющих бурение нефтяных и газовых скважин',
    },
    {
      code: 63652,
      title: 'Б.2.5.',
      description:
        'Подготовка по области аттестации Б.2.5 руководителей и специалистов организаций, эксплуатирующих нефтепромысловые трубопроводы для транспорта нефти и газа',
    },
    {
      code: 63653,
      title: 'Б.2.6.',
      description:
        'Подготовка по области аттестации Б.2.6 руководителей и специалистов организаций, осуществляющих разведку и разработку морских месторождений углеводородного сырья',
    },
    {
      code: 63654,
      title: 'Б.2.7.',
      description:
        'Подготовка по области аттестации Б.2.7 руководителей и специалистов организаций, эксплуатирующих магистральные нефтепроводы и нефтепродуктопроводы',
    },
    {
      code: 63655,
      title: 'Б.2.8.',
      description:
        'Подготовка по области аттестации Б.2.8 руководителей и специалистов организаций, эксплуатирующих магистральные газопроводы',
    },
    {
      code: 63656,
      title: 'Б.2.9.',
      description:
        'Подготовка по области аттестации Б.2.9 руководителей и специалистов организаций, эксплуатирующих магистральные трубопроводы для транспортировки жидкого аммиака',
    },
    {
      code: 63657,
      title: 'Б.2.10.',
      description:
        'Подготовка по области аттестации Б.2.10 руководителей и специалистов организаций, эксплуатирующих подземные хранилища газа',
    },
  ],
  3: [
    {
      code: 63660,
      title: 'Б.3.1.',
      description:
        'Подготовка по области аттестации Б.3.1 руководителей и специалистов литейного производства черных и цветных металлов',
    },
    {
      code: 63661,
      title: 'Б.3.2.',
      description:
        'Подготовка по области аттестации Б.3.2 руководителей и специалистов медно-никелевого производства',
    },
    {
      code: 63662,
      title: 'Б.3.3.',
      description:
        'Подготовка по области аттестации Б.3.3 руководителей и специалистов коксохимического производства',
    },
    {
      code: 63663,
      title: 'Б.3.4.',
      description:
        'Подготовка по области аттестации Б.3.4 руководителей и специалистов организаций, производящих первичный алюминий',
    },
    {
      code: 63664,
      title: 'Б.3.5.',
      description:
        'Подготовка по области аттестации Б.3.5 руководителей и специалистов организаций, производящих редкие, благородные и другие цветные металлы',
    },
    {
      code: 63665,
      title: 'Б.3.6.',
      description:
        'Подготовка по области аттестации Б.3.6 руководителей и специалистов организаций доменных и сталеплавильных производств',
    },
    {
      code: 63666,
      title: 'Б.3.7.',
      description:
        'Подготовка по области аттестации Б.3.7 руководителей и специалистов организаций ферросплавных производств',
    },
    {
      code: 63667,
      title: 'Б.3.8.',
      description:
        'Подготовка по области аттестации Б.3.8 руководителей и специалистов организаций с полным металлургическим циклом',
    },
    {
      code: 63668,
      title: 'Б.3.9.',
      description:
        'Подготовка по области аттестации Б.3.9 руководителей и cпециалистов организаций, осуществляющих проектирование, строительство, реконструкцию, капитальный ремонт объектов металлургической промышленности',
    },
    {
      code: 63669,
      title: 'Б.3.10.',
      description:
        'Подготовка по области аттестации Б.3.10 руководителей и специалистов энергетических служб металлургических предприятий',
    },
  ],
  4: [
    {
      code: 63671,
      title: 'Б.4.1.',
      description:
        'Подготовка по области аттестации Б.4.1 руководителей и специалистов организаций, осуществляющих обогащение полезных ископаемых',
    },
    {
      code: 63672,
      title: 'Б.4.2.',
      description:
        'Подготовка по области аттестации Б.4.2 руководителей и специалистов организаций, осуществляющих строительство, реконструкцию, капитальный ремонт подземных сооружений',
    },
    {
      code: 63673,
      title: 'Б.4.3.',
      description:
        'Подготовка по области аттестации Б.4.3 руководителей и специалистов организаций, осуществляющих разработку месторождений полезных ископаемых открытым способом',
    },
    {
      code: 63674,
      title: 'Б.4.4.',
      description:
        'Подготовка по области аттестации Б.4.4 руководителей и специалистов организаций, осуществляющих разработку месторождений полезных ископаемых подземным способом',
    },
    {
      code: 63675,
      title: 'Б.4.5.',
      description:
        'Подготовка по области аттестации Б.4.5 руководителей и специалистов организаций, осуществляющих проектирование опасных производственных объектов горной промышленности',
    },
  ],
  5: [
    {
      code: 63677,
      title: 'Б.5.1.',
      description:
        'Подготовка по области аттестации Б.5.1 руководителей и специалистов организаций, осуществляющих разработку угольных месторождений открытым способом',
    },
    {
      code: 63678,
      title: 'Б.5.2.',
      description:
        'Подготовка по области аттестации Б.5.2 руководителей и специалистов организаций, осуществляющих обогащение и брикетирование углей (сланцев)',
    },
    {
      code: 63679,
      title: 'Б.5.3.',
      description:
        'Подготовка по области аттестации Б.5.3 руководителей и специалистов организаций, осуществляющих разработку угольных месторождений подземным способом',
    },
  ],
  6: [
    {
      code: 63681,
      title: 'Б.6.1.',
      description:
        'Подготовка по области аттестации Б.6.1 руководителей и специалистов организаций в области маркшейдерского обеспечения безопасного ведения горных работ при осуществлении работ, связанных с пользованием недрами и их проектированием',
    },
    {
      code: 63682,
      title: 'Б.6.2.',
      description:
        'Подготовка по области аттестации Б.6.2 руководителей и специалистов организаций в области маркшейдерского обеспечения безопасного ведения горных работ при осуществлении пользования недрами в целях, не связанных с добычей полезных ископаемых, а также строительства и эксплуатации гидротехнических сооружений',
    },
    {
      code: 63683,
      title: 'Б.6.3.',
      description:
        'Подготовка по области аттестации Б.6.3 руководителей и специалистов организаций в области маркшейдерского обеспечения безопасного ведения горных работ при осуществлении разработки месторождений полезных ископаемых подземным способом',
    },
    {
      code: 63684,
      title: 'Б.6.4.',
      description:
        'Подготовка по области аттестации Б.6.4 руководителей и специалистов организаций в области маркшейдерского обеспечения безопасного ведения горных работ при осуществлении разработки месторождений полезных ископаемых открытым способом',
    },
    {
      code: 63685,
      title: 'Б.6.5.',
      description:
        'Подготовка по области аттестации Б.6.5 руководителей и специалистов организаций в области маркшейдерского обеспечения безопасного ведения горных работ при осуществлении разработки месторождений углеводородного сырья и гидроминеральных ресурсов',
    },
  ],
  7: [
    {
      code: 63687,
      title: 'Б.7.1.',
      description:
        'Подготовка по области аттестации Б.7.1 руководителей и специалистов организаций, эксплуатирующих системы газораспределения и газопотребления',
    },
    {
      code: 63688,
      title: 'Б.7.2.',
      description:
        'Подготовка по области аттестации Б.7.2 руководителей и специалистов организаций, эксплуатирующих объекты, использующие сжиженные углеводородные газы',
    },
    {
      code: 63689,
      title: 'Б.7.3.',
      description:
        'Подготовка по области аттестации Б.7.3 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение и капитальный ремонт сетей газораспределения и газопотребления',
    },
    {
      code: 63690,
      title: 'Б.7.4.',
      description:
        'Подготовка по области аттестации Б.7.4 руководителей и специалистов организаций, эксплуатирующих автогазозаправочные станции газомоторного топлива',
    },
  ],
  8: [
    {
      code: 63692,
      title: 'Б.8.1.',
      description:
        'Подготовка по области аттестации Б.8.1 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются котлы (паровые, водогрейные, электрические, а также с органическими и неорганическими теплоносителями)',
    },
    {
      code: 63693,
      title: 'Б.8.2.',
      description:
        'Подготовка по области аттестации Б.8.2 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются трубопроводы пара и горячей воды',
    },
    {
      code: 63694,
      title: 'Б.8.3.',
      description:
        'Подготовка по области аттестации Б.8.3 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются сосуды, работающие под избыточным давлением',
    },
    {
      code: 63695,
      title: 'Б.8.4.',
      description:
        'Подготовка по области аттестации Б.8.4 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются медицинские и водолазные барокамеры',
    },
    {
      code: 63696,
      title: 'Б.8.5.',
      description:
        'Подготовка по области аттестации Б.8.5 руководителей и специалистов организаций, осуществляющих наполнение, техническое освидетельствование и ремонт баллонов для хранения и транспортирования сжатых, сжиженных и растворенных под давлением газов, применяемых на опасных производственных объектах',
    },
    {
      code: 63697,
      title: 'Б.8.6.',
      description:
        'Подготовка по области аттестации Б.8.6 руководителей и специалистов организаций, осуществляющих деятельность, связанную с проектированием, строительством, реконструкцией, капитальным ремонтом и техническим перевооружением опасных производственных объектов, изготовлением, монтажом (демонтажем), наладкой, обслуживанием и ремонтом (реконструкцией) оборудования, работающего под избыточным давлением, применяемого на опасных производственных объектах',
    },
  ],
  9: [
    {
      code: 63699,
      title: 'Б.9.1.',
      description:
        'Подготовка по области аттестации Б.9.1 руководителей и специалистов организаций, осуществляющих эксплуатацию и капитальный ремонт опасных производственных объектов, на которых используются эскалаторы в метрополитенах, эксплуатацию (в том числе обслуживание и ремонт) эскалаторов в метрополитенах',
    },
    {
      code: 63700,
      title: 'Б.9.2.',
      description:
        'Подготовка по области аттестации Б.9.2 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение, консервацию и ликвидацию опасных производственных объектов, на которых используются эскалаторы в метрополитенах, а также изготовление, монтаж и наладку эскалаторов',
    },
    {
      code: 63701,
      title: 'Б.9.3.',
      description:
        'Подготовка по области аттестации Б.9.3 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются подъемные сооружения, предназначенные для подъема и перемещения грузов',
    },
    {
      code: 63702,
      title: 'Б.9.4.',
      description:
        'Подготовка по области аттестации Б.9.4 руководителей и специалистов организаций, эксплуатирующих опасные производственные объекты, на которых используются подъемные сооружения, предназначенные для подъема и транспортировки людей',
    },
    {
      code: 63703,
      title: 'Б.9.5.',
      description:
        'Подготовка по области аттестации Б.9.5 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение, капитальный ремонт, консервацию, ликвидацию опасных производственных объектов, на которых используются подъемные сооружения',
    },
    {
      code: 63704,
      title: 'Б.9.6.',
      description:
        'Подготовка по области аттестации Б.9.6 руководителей и специалистов организаций, осуществляющих монтаж, наладку, обслуживание, ремонт, реконструкцию или модернизацию подъемных сооружений, применяемых на опасных производственных объектах',
    },
    {
      code: 63705,
      title: 'Б.9.7.',
      description:
        'Подготовка по области аттестации Б.9.7 руководителей и специалистов организаций, осуществляющих эксплуатацию и капитальный ремонт опасных производственных объектов, на которых используются пассажирские канатные дороги и (или) фуникулеры, эксплуатацию (в том числе обслуживание и ремонт) пассажирских канатных дорог и (или) фуникулеров',
    },
    {
      code: 63706,
      title: 'Б.9.8.',
      description:
        'Подготовка по области аттестации Б.9.8 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение, консервацию и ликвидацию опасных производственных объектов, на которых используются пассажирские канатные дороги и (или) фуникулеры, а также изготовление, монтаж и наладку пассажирских канатных дорог и (или) фуникулеров',
    },
    {
      code: 63707,
      title: 'Б.9.9.',
      description:
        'Подготовка по области аттестации Б.9.9 руководителей и специалистов организаций, осуществляющих эксплуатацию и капитальный ремонт опасных производственных объектов, на которых используются грузовые подвесные канатные дороги, эксплуатацию (в том числе обслуживание и ремонт) грузовых подвесных канатных дорог',
    },
    {
      code: 63708,
      title: 'Б.9.10.',
      description:
        'Подготовка по области аттестации Б.9.10 руководителей и специалистов организаций, осуществляющих проектирование, строительство, реконструкцию, техническое перевооружение, консервацию и ликвидацию опасных производственных объектов, на которых используются грузовые подвесные канатные дороги, а также изготовление, монтаж и наладку грузовых подвесных канатных дорог',
    },
  ],
  10: [
    {
      code: 63710,
      title: 'Б.10.1.',
      description:
        'Подготовка по области аттестации Б.10.1 руководителей и специалистов организаций, осуществляющих транспортирование опасных веществ железнодорожным транспортом',
    },
    {
      code: 63711,
      title: 'Б.10.2.',
      description:
        'Подготовка по области аттестации Б.10.2 руководителей и специалистов организаций, осуществляющих транспортирование опасных веществ автомобильным транспортом',
    },
  ],
  11: [
    {
      code: 63713,
      title: 'Б.11.1.',
      description:
        'Подготовка по области аттестации Б.11.1 руководителей и специалистов организаций, осуществляющих строительство, эксплуатацию, реконструкцию, капитальный ремонт, техническое перевооружение, консервацию и ликвидацию объектов хранения и переработки растительного сырья',
    },
    {
      code: 63714,
      title: 'Б.11.2.',
      description:
        'Подготовка по области аттестации Б.11.2 руководителей и специалистов организаций, разрабатывающих проектную, конструкторскую и иную документацию для опасных объектов хранения и переработки растительного сырья',
    },
    {
      code: 63715,
      title: 'Б.11.3.',
      description:
        'Подготовка по области аттестации Б.11.3 руководителей и специалистов организаций, осуществляющих изготовление, монтаж, наладку, ремонт, техническое освидетельствование, реконструкцию и эксплуатацию технических устройств (машин и оборудования), применяемых на объектах хранения и переработки растительного сырья',
    },
  ],
  12: [
    {
      code: 63717,
      title: 'Б.12.1.',
      description:
        'Подготовка по области аттестации Б.12.1 руководителей и специалистов организаций, осуществляющих взрывные работы в подземных выработках и на поверхности рудников (объектах горнорудной и нерудной промышленности), угольных и сланцевых шахт, опасных (не опасных) по газу или пыли, и специальные взрывные работы',
    },
    {
      code: 63718,
      title: 'Б.12.2.',
      description:
        'Подготовка по области аттестации Б.12.2 руководителей и специалистов организаций, осуществляющих взрывные работы на открытых горных разработках и специальные  взрывные работы',
    },
  ],
  13: [
    {
      code: 64843,
      title: 'Г.1.1.',
      description:
        'Подготовка по области аттестации Г.1.1 руководителей и специалистов организаций, эксплуатирующих электроустановки',
    },
  ],
  14: [
    {
      code: 64845,
      title: 'Г.2.1.',
      description:
        'Подготовка по области аттестации Г.2.1 руководителей и специалистов организаций, эксплуатирующих тепловые электрические станции',
    },
    {
      code: 64846,
      title: 'Г.2.2.',
      description:
        'Подготовка по области аттестации Г.2.2 руководителей и специалистов организаций, эксплуатирующих электрические сети',
    },
    {
      code: 64847,
      title: 'Г.2.3.',
      description:
        'Подготовка по области аттестации Г.2.3 руководителей и специалистов организаций, эксплуатирующих гидроэлектростанции',
    },
    {
      code: 64848,
      title: 'Г.2.4.',
      description:
        'Подготовка по области аттестации Г.2.4 руководителей и специалистов организаций, эксплуатирующих объекты возобновляемых источников энергии',
    },
    {
      code: 64849,
      title: 'Г.2.5.',
      description:
        'Подготовка по области аттестации Г.2.5 руководителей и специалистов организаций, отвечающих за организацию оперативно-диспетчерского управления в электроэнергетике',
    },
  ],
};
