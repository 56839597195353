import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    paddingTop: 0,
  },
  search: {
    marginHorizontal: 0,
  },
  testContainer: {
    backgroundColor: '#F6F6F6',
    marginTop: 10,
    marginBottom: 5,
    borderRadius: 8,
    padding: 20,
    minHeight: 50,
  },
  titleTest: {
    fontWeight: 'bold',
    color: '#252525',
  },
  answersContainer: {
    flexDirection: 'row',
    marginTop: 15,
  },
  titleDetail: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  filter: {
    marginTop: 10,
  },
  resultList: {
    marginTop: 15,
  },
});
