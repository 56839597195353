import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
  },
  politics: {
    textAlign: 'center',
    fontSize: 12,
  },
  link: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});
