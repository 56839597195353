import React, { useState, useEffect } from 'react';
import {
  Switch,
  Text,
  View,
  Pressable,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CardTemplate, GridTemplate } from '@components/templates';
import Minus from '@images/icons/minus.svg';
import Plus from '@images/icons/plus.svg';
import { HeaderProfile } from '@components/organisms/headers';
import translations from '@translations/ru.json';
import { styles } from './styles';
import * as actions from '@actions';

const texts = translations.settings;
/*
const resetAction = CommonActions.reset({
  index: 1,
  routes: [{ name: 'FirstScreen' }],
});
*/
const SettingsView = ({ navigation, fontSize, mixAnswers, setSettings }) => {
  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(width < 1070);

  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  return (
    <View style={styles.root}>
      <HeaderProfile welcome />
      <GridTemplate
        style={
          isMobile
            ? { alignSelf: 'stretch' }
            : { width: 600, alignSelf: 'center' }
        }
      >
        <CardTemplate style={styles.cardContainer}>
          <View style={styles.settingContainer}>
            <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
              Перемешивать ответы
            </Text>
            <Switch
              ios_backgroundColor="#e8e8e8"
              thumbColor="#FFFFFF"
              trackColor={{ false: '#e8e8e8', true: '#4CD964' }}
              onValueChange={() => setSettings({ mixAnswers: !mixAnswers })}
              value={mixAnswers}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 35,
            }}
          >
            <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
              Размер шрифта
            </Text>
            <View style={styles.settingFontContainer}>
              <Pressable
                onPress={() =>
                  fontSize > 10 && setSettings({ fontSize: fontSize - 1 })
                }
                style={styles.button}
              >
                <Minus />
              </Pressable>
              <View style={styles.fontSizeContainer}>
                <Text style={{ fontSize }}>{fontSize}</Text>
              </View>
              <Pressable
                onPress={() =>
                  fontSize < 20 && setSettings({ fontSize: fontSize + 1 })
                }
                style={[styles.button, styles.rightButton]}
              >
                <Plus />
              </Pressable>
            </View>
          </View>
        </CardTemplate>
      </GridTemplate>
    </View>
  );
};

const mapStateToProps = ({ settings: { fontSize, mixAnswers } }) => ({
  fontSize,
  mixAnswers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSettings: actions.setSettings,
    },
    dispatch,
  );

export const Settings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsView);
