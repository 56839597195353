import React from 'react';
import { Text } from 'react-native';

export const LastUpdateDate = ({ style }) => (
  <Text style={style}>
    Официальные вопросы Ростехнадзора на 2024 год.
    {'\n'}
    Последняя дата обновления: 04.06.2024г.
  </Text>
);
