import { StyleSheet, Platform } from 'react-native';

import { colors } from '@styles/colors';

const styleBody = Platform.select({
  ios: { flex: 1 },
  android: { minHeight: '100%', flex: 1 },
});

export const styles = StyleSheet.create({
  root: {
    alignContent: 'center',
    alignItems: 'center',
  },
  backstage: {
    backgroundColor: 'rgba(0,0,0,0.65)',
  },
  modalContainer: {
    marginTop: 25,
    marginBottom: 25,
    padding: 15,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    alignSelf: 'stretch',
    alignItems: 'center',
    //flex: 1,
  },
  body: {
    ...styleBody,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
  },

  text: {
    marginTop: 15,
    marginBottom: 22,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '600',
    color: colors.Gray_1,
  },
  btn: {
    width: '100%',
    marginBottom: 10,
    borderRadius: 8,
  },
});
