import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  useWindowDimensions,
  View,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import tests from '@data/tests.js';
import questions from '@data/questions';
import { useNavigation } from '@react-navigation/native';

import { styles } from './styles';

const TestCardView = ({ screen, testResults, item, onPressHandler, id }) => {
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 800);
  const navigation = useNavigation();

  useEffect(() => {
    // setCurrentResults(testResults[]);
    const successTestResultCount =
      testResults[item.code]?.filter((r, i) => r.isUserCorrectAnswer)?.length ||
      0;
    const questionsCount = (id && questions[id]?.[item.code]?.length) || 1;
    const newCompletedPercentage =
      (successTestResultCount / questionsCount) * 100;
    setCompletedPercentage(newCompletedPercentage);
  }, [testResults]);

  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  return (
    <TouchableOpacity
      key={item.id || item.code}
      style={[
        styles.testContainer,
        isMobile ? { alignSelf: 'stretch' } : { width: 500 },
        { overflow: 'hidden' },
      ]}
      onPress={() => {
        screen > 1
          ? onPressHandler(item)
          : navigation.push('Tests', {
              blocks: tests[item.id],
              screen: screen + 1,
              id: item.id,
              name: item.title,
            });
      }}
    >
      {screen > 1 && (
        <View style={{ position: 'absolute', top: 0, left: 0 }}>
          <ProgressBarAnimated
            borderWidth={0}
            width={isMobile ? width - 30 : 500}
            height={180}
            value={completedPercentage}
            // backgroundColor="#DCFAEE"
            backgroundColor="#A5D9C4"
            baseBackgroundColor="#fff"
            underlyingColor="#F6F6F6"
            backgroundColorOnComplete="#6CC644"
          />
          <View style={styles.titleAbsolute}>
            <Text style={styles.titleTest}>{item.title}</Text>
            <Text style={[styles.titleTest, styles.titleDetail]}>
              {item.description}
            </Text>
          </View>
        </View>
      )}

      <Text style={styles.titleTest}>{item.title}</Text>
      <Text style={[styles.titleTest, styles.titleDetail]}>
        {item.description}
      </Text>
    </TouchableOpacity>
  );
};

const mapStateToProps = ({ results }) => ({
  testResults: results,
});

export const TestCard = connect(mapStateToProps)(TestCardView);
