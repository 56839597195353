import React, { useState } from 'react';
import { Text, Linking, Alert } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button } from '@components/atoms';
import { AuthTemplate } from '@components/templates';
// import * as actions from '@actions';
import { isIOS } from '@lib/deviceInfo';
import translations from '@translations/ru.json';
// import { openInbox } from 'react-native-email-link';
import { sendPasswordResetEmail } from 'firebase/auth';
import { styles } from './styles';
import { useLinkTo } from '@react-navigation/native';

const texts = translations.authScreen;

const errors = {
  'auth/user-not-found': 'пользователь не найден',
  'auth/too-many-requests': 'слишком много запросов, попробуйти позже',
  'auth/network-request-failed': 'технические проблемы, попробуйте позже',
};

const onChangeField =
  (field, error, changeFunction, clearFunction) => (value) => {
    if (error) {
      clearFunction(field);
    }

    changeFunction(value);
  };

const isValidForm = (email, setError) => {
  let isValid = true;
  let error = '';
  if (!/.+@.+\..+/i.test(email)) {
    error = [texts.errors.restorePassword.invalidEmail];
    isValid = false;
  }

  if (!isValid) {
    setError(error);
  }

  return isValid;
};

const ResetPasswordView = ({ navigation }) => {
  const [email, changeEmail] = useState('');
  const [error, setRestorePasswordError] = useState('');
  const [isFetching, setFetching] = useState(false);
  const linkTo = useLinkTo();

  return (
    <AuthTemplate>
      {/*<Text style={styles.title}>{texts.forgotPassword.title}</Text>*/}
      <Text style={styles.description}>{texts.forgotPassword.description}</Text>
      <Input
        customStyle={styles.input}
        value={email}
        onChangeText={onChangeField(
          '',
          error,
          changeEmail,
          setRestorePasswordError,
        )}
        error={error}
        icon="email"
        placeholder={texts.email}
        dataDetectorTypes="address"
        keyboardType="email-address"
        textContentType="emailAddress"
      />
      <Button
        customStyles={styles.btn}
        title={texts.forgotPassword.send}
        onPress={() => {
          if (!isValidForm(email, setRestorePasswordError)) {
            return;
          }
          setFetching(true);
          sendPasswordResetEmail(auth, email)
            .then((res) => {
              console.log('request send!');
              setFetching(false);
              alert(`Письмо отправленно на ${email}!`);
              linkTo('/signin');
            })
            .catch((e) => {
              setFetching(false);
              setRestorePasswordError(errors[e.code]);
              console.log('err->', e);
            });
        }}
        disabled={!email}
        isFetching={isFetching}
      />
    </AuthTemplate>
  );
};

const mapStateToProps = ({ restorePasswordState }) => ({
  restorePasswordState,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordView);
