import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  ScrollView,
  FlatList,
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from '@components/atoms';
import { Final } from '@components/modals/final';
import { GridTemplate } from '@components/templates';
import { CommonActions } from '@react-navigation/native';
import * as actions from '@actions';
import { HeaderTestFlow } from '@components/organisms/headers';
import data from '@data/questions';
import { styles } from './styles';
import shuffle from 'lodash.shuffle';
import { DeprecatedFavouritesWarning } from './components/deprecated-favourites-warning';

import Selected from '@images/icons/selected.svg';
import Disabled from '@images/icons/disabled.svg';
import Checked from '@images/icons/check.svg';
import Error from '@images/icons/error.svg';
import Favourites from '@components/atoms/tab-icon/Favourites';
import Refresh from '@images/icons/refresh.svg';
import Arrow from '@images/icons/arrow.svg';
import ArrowRight from '@images/icons/arrow_right.svg';

const QuestionsView = ({
  route,
  navigation,
  hasFavouritesDeprecated,
  favourites,
  setFavourites,
  fontSize,
  mixAnswers,
  success,
  total,
  addUserData,
  resultsRedux,
  setResultsRedux,
}) => {
  const { code, id, mode, title, name, description } = route?.params;

  let { width, height } = useWindowDimensions();

  const scrollRef = useRef();

  const shift = 259;

  const [questions, setQuestions] = useState(data[id][code]);
  const [count, setCount] = useState(0);
  // const [selected, setSelected] = useState(null);
  // const [selectedAnswerIndexes, setSelectedAnswerIndexes] = useState([]);
  const [results, setResults] = useState([]);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('success');
  const [answers, setAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [nameTest, setNameTest] = useState(name);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [buttonTitle, setButtonTitle] = useState('Ответить');
  const [isMobile, setIsMobile] = useState(width < 1070);
  const [rightComponent, setRightComponent] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  React.useLayoutEffect(() => {
    let newQuestions = questions;
    if (!questions[0]?.text.match(/^\(/)) {
      newQuestions = questions.map((item, i) => {
        item.text = `(${i + 1})  ${item.text}`;
        return item;
      });
    }
    switch (mode) {
      case 'exam': {
        setRightComponent(() => (
          <View style={styles.headerRightContainer}>
            <Text
              style={{ fontSize: 16, fontWeight: 'bold', color: '#FFFFFF' }}
            >
              Экзамен
            </Text>
          </View>
        ));
        //--перемешиваем и берём первые 20 вопросов --
        newQuestions = _.shuffle(newQuestions).slice(0, 20);
        break;
      }

      case 'test': {
        setRightComponent(() => (
          <TouchableOpacity
            onPress={() => {
              setResults([]);
              onPressHandler(0);
            }}
            style={styles.headerResetContainer}
          >
            <Text style={styles.titleReset}>Сброс</Text>
            <Refresh />
          </TouchableOpacity>
        ));
        setResults(resultsRedux[code] || []);
        resultsRedux[code] && onPressHandler(resultsRedux[code].length, 100);
        break;
      }
      case 'favourites': {
        setRightComponent(() => (
          <TouchableOpacity
            onPress={() => {
              setResults([]);
              onPressHandler(0);
            }}
            style={styles.headerResetContainer}
          >
            <Text style={styles.titleReset}>Сброс</Text>
            <Refresh />
          </TouchableOpacity>
        ));
        setNameTest('Тест по Избранному');
        newQuestions = favourites;
        break;
      }

      case 'fixErrors': {
        navigation.setOptions({
          headerRight: () => (
            <View
              style={[styles.headerRightContainer, styles.redRightContainer]}
            >
              <Text
                style={{ fontSize: 14, fontWeight: 'bold', color: '#FFFFFF' }}
              >
                Мои ошибки
              </Text>
            </View>
          ),
        });
        const errorResults = resultsRedux[code].filter(
          (q, i) => !q.isUserCorrectAnswer,
        );
        newQuestions = errorResults.map(
          (r, i) => newQuestions[r.countQuestion],
        );
      }
    }
    setQuestions(newQuestions);
  }, [navigation]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    addUserData({
      progress: {
        success: status === 'success' ? ++success : success,
        total: ++total,
      },
    });
  }, [visible]);

  useEffect(() => {
    setAnswers(
      mixAnswers
        ? shuffle(questions[count]?.answers)
        : questions[count]?.answers,
    );
    setCorrectAnswers(questions[count]?.correctAnswers);
  }, [count, mixAnswers, questions]);

  useEffect(() => {
    if (mode === 'test') {
      setResultsRedux({ [code]: results });
      return;
    }
    if (mode === 'fixErrors') {
      if (status !== 'failure') {
        const hasError = !!results.find((r, i) => !r.isUserCorrectAnswer);
        hasError && setStatus('failure');
      }
      // если результаты есть в редакс то перезаписываем правильный ли ответ
      const newResultRedux = resultsRedux[code].map((resRedux, i) => {
        const result = results.find(
          (r) => r.correctAnswers[0] == resRedux.correctAnswers[0],
        );
        return {
          ...resRedux,
          ...(result && { isUserCorrectAnswer: result.isUserCorrectAnswer }),
        };
      });

      setResultsRedux({ [code]: newResultRedux });
      return;
    }
    if (mode === 'favourites') {
      const hasError = !!results.find((r, i) => !r.isUserCorrectAnswer);
      hasError && setStatus('failure');
      // console.log('hasError:', hasError);
      return;
    }
    if (results.length > 0) {
      const arrErr = results.filter((item) => !item.isUserCorrectAnswer);
      arrErr.length > 2 && status !== 'failure' && setStatus('failure');
    }
  }, [results, status, mode]);

  //--- удалить
  useEffect(() => {
    console.log('name->', name);
  }, [name]);

  useEffect(() => {
    //setSelectedAnswerInState

    console.log('offset->', offset);
    setTimeout(
      () =>
        scrollRef.current.scrollToOffset({
          offset: offset,
          animated: true,
        }),
      100,
    );
  }, [offset]);

  // -- новая логика - устанавливаем выделенные ответы в стейт
  const setSelectedAnswerInState = (answer) => {
    if (correctAnswers.length == 1) {
      setSelectedAnswers([answer]);
      return;
    }
    const newSelectedAnswers = _.without(selectedAnswers, answer);
    if (
      _.isEqual([...newSelectedAnswers].sort(), [...selectedAnswers].sort())
    ) {
      setSelectedAnswers([...selectedAnswers, answer]);
    } else {
      setSelectedAnswers(newSelectedAnswers);
    }
  };

  const getStyleCount = (i) => {
    const result = results.find((item) => item.countQuestion === i);
    if (!result) {
      return null;
    }
    const isCorrectAnswer = result?.isUserCorrectAnswer;
    return isCorrectAnswer ? styles.successCount : styles.errorCount;
  };

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      key={item.code}
      onPress={() => onPressHandler(index)}
      style={[
        styles.countContainer,
        count === index && styles.countActive,
        getStyleCount(index),
      ]}
    >
      <Text style={styles.titleCount}>{index + 1}</Text>
    </TouchableOpacity>
  );

  const getIcon = (indexAnswer, answer) => {
    const result = results.find((item) => item.countQuestion == count);
    if (result) {
      const { userAnswers, correctAnswers } = result;
      if (correctAnswers.includes(answer)) {
        return <Checked />;
      }

      if (userAnswers.includes(answer)) {
        return <Error />;
      }
      return <Disabled />;
    }

    if (selectedAnswers.includes(answer)) {
      return <Selected />;
    } else {
      return <Disabled />;
    }
  };

  const onPressHandler = (i, delay = 100) => {
    const correction = width < 550 ? width / 2 - 36 : 257;
    setCount(i);
    setButtonTitle('Ответить');
    setSelectedAnswers([]);
    setOffset(i * 37 - correction);
    /* setTimeout(
      () =>
        scrollRef.current.scrollToOffset({
          offset: i * 37 - correction,
          animated: true,
        }),
      delay,
    ); */
  };

  const onPressCheckHandler = () => {
    const hasResult = !!results.find((item) => item.countQuestion == count);

    if (hasResult) {
      if (count === questions.length - 1) {
        return results.length === questions.length ? setVisible(true) : null;
      }
      if (status == 'failure' && mode == 'exam') {
        return setVisible(true);
      }
      onPressHandler(count + 1);
      return;
    }

    // -- новая логика проверки ответа
    const isUserCorrectAnswer = _.isEqual(
      [...correctAnswers].sort(),
      [...selectedAnswers].sort(),
    );

    const result = {
      countQuestion: count,
      isUserCorrectAnswer,
      userAnswers: selectedAnswers,
      correctAnswers,
    };

    setResults([...results, result]);
    setButtonTitle('Далее');
  };

  const onPressBack = () => {
    navigation.dispatch(
      CommonActions.reset({ routes: [{ name: 'MainMenu' }] }),
    );
    setVisible(false);
  };

  const onPressAgain = () => {
    navigation.replace('Questions', route?.params);
    setVisible(false);
  };

  const isFavourites = (question) => {
    const result = favourites.find((item) => item.code === question.code);
    return !!result;
  };

  const showDeprecatedFavouritesWarning =
    mode === 'favourites' && hasFavouritesDeprecated;

  if (questions.length === 0 && showDeprecatedFavouritesWarning)
    return (
      <GridTemplate style={[styles.root, { flex: 1 }]}>
        <HeaderTestFlow leftTitles={['Тесты', nameTest]} />
        {showDeprecatedFavouritesWarning && (
          <DeprecatedFavouritesWarning
            addActualQuestionsCall
            containerStyle={styles.deprecatedWarning}
          />
        )}
      </GridTemplate>
    );

  return (
    <GridTemplate style={[styles.root, { flex: 1 }]}>
      <Final
        visible={visible}
        onToggle={() => setVisible(false)}
        onPressBack={onPressBack}
        onPressAgain={onPressAgain}
        status={status}
        mode={mode}
        titleTest={title}
        description={description}
      />
      <HeaderTestFlow
        leftTitles={['Тесты', nameTest]}
        rightComponent={rightComponent}
      />
      {showDeprecatedFavouritesWarning && (
        <DeprecatedFavouritesWarning
          containerStyle={styles.deprecatedWarning}
        />
      )}
      <View style={styles.listContainer}>
        {!isMobile && (
          <TouchableOpacity
            style={styles.arrows}
            onPress={() => {
              if (offset <= 0) {
                return;
              }
              setOffset(offset - 2 * shift);
            }}
          >
            <Arrow />
          </TouchableOpacity>
        )}
        <FlatList
          ref={scrollRef}
          data={questions}
          renderItem={renderItem}
          initialNumToRender={5}
          style={[styles.body, { width: width }]}
          maxToRenderPerBatch={5}
          keyExtractor={(item) => item.code}
          horizontal
          removeClippedSubviews={true}
          windowSize={5}
          getItemLayout={(data, index) => ({
            length: 37,
            offset: 37 * index,
            index,
          })}
          showsHorizontalScrollIndicator={false}
          updateCellsBatchingPeriod={100}
        />
        {!isMobile && (
          <TouchableOpacity
            style={styles.arrows}
            onPress={() => {
              if (offset < 0) {
                setOffset(2 * shift);
              } else {
                setOffset(offset + 2 * shift);
              }
            }}
          >
            <ArrowRight />
          </TouchableOpacity>
        )}
      </View>
      <ScrollView>
        <View style={styles.questionsContainer}>
          <Text style={[styles.titleQuestion, { fontSize }]}>
            {mode === 'favourites' && (
              <Text style={{ fontSize, fontWeight: 'bold' }}>
                {questions[count]?.block}
                {'  '}
              </Text>
            )}
            {questions[count]?.text}
          </Text>
          <TouchableOpacity
            onPress={() => setFavourites({ ...questions[count], block: title })}
            style={{ flex: 1, alignItems: 'center' }}
          >
            <Favourites
              fill={isFavourites(questions[count]) ? '#A5D9C4' : 'none'}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.answersContainer}>
          {answers?.map((answer, i) => (
            <TouchableOpacity
              key={i}
              onPress={() => {
                setSelectedAnswerInState(answer);
              }}
              style={styles.answer}
            >
              <View style={{ flex: 1, alignItems: 'center' }}>
                {getIcon(i, answer)}
              </View>
              <View style={{ flex: 5, alignSelf: 'center' }}>
                <Text style={[styles.titleQuestion, { fontSize }]}>
                  {answer}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
          <View style={{ height: 10 }} />
        </View>
        <Button
          onPress={onPressCheckHandler}
          title={buttonTitle}
          disabled={!selectedAnswers.length}
          customStyles={styles.btn}
        />
      </ScrollView>
    </GridTemplate>
  );
};

const mapStateToProps = ({
  favourites,
  settings: { fontSize, mixAnswers },
  user: {
    progress: { success, total },
  },
  results,
}) => {
  const hasFavouritesDeprecated = favourites.some(
    (favourite) => favourite.deprecated,
  );

  return {
    hasFavouritesDeprecated,
    favourites: hasFavouritesDeprecated
      ? favourites.filter((favourite) => !favourite.deprecated)
      : favourites,
    fontSize,
    mixAnswers,
    success,
    total,
    resultsRedux: results,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFavourites: actions.setFavourites,
      addUserData: actions.addUserData,
      setResultsRedux: actions.setResults,
    },
    dispatch,
  );

export const Questions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionsView);
